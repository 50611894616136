import {
  Button,
  Form,
  FormInstance,
  Icons,
  Input,
  List,
  Modal,
  Space,
  Table,
  TableColumnProps,
  Tooltip,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { IFormEmailResponse, IFormResponse } from "interfaces";
import { createRef, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { TableModal } from "./TableModal/TableModal";
import api from "api/api";
import {
  defaultButtonFilled,
  defaultButtonOutline,
  defaultCreateButtonProps,
  defaultHeader,
} from "components";
import Highlighter from "react-highlight-words";
const { EditFilled, EyeFilled } = Icons;

export const FormList = () => {
  const t = useTranslate();
  const [showTableModal, setShowTableModal] = useState(false);
  const [tableToShowRecord, setTableToShowRecord] = useState<IFormResponse>();
  const [tableData, setTableData] = useState<IFormResponse[]>();
  const columnTitle = [
    {
      label: t("table.id"),
      index: "id",
    },
    {
      label: t("table.name"),
      index: "name",
    },
    {
      label: t("table.languageName"),
      index: "languageName",
    },
    {
      label: t("table.createDate"),
      index: "createDate",
    },
  ];
  const [showEditModal, setShowEditModal] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState<IFormResponse | null>(null);
  const formRef = createRef<FormInstance>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [singleFormEmailResponse, setSingleFormEmailResponse] =
    useState<IFormEmailResponse | null>(null);
  const [showFailModal, setShowFailModal] = useState(false);
  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);
  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }
  type DataIndex = keyof DataType;

  const { data, isFetching } = useCustom<IFormResponse[]>({
    url: `/forms`,
    method: "get",
  });

  async function editFormEmails() {
    const response = await api.form.editFormEmails(
      recordToEdit?.id!,
      formRef.current?.getFieldValue("to") ?? "",
      formRef.current?.getFieldValue("cc") ?? ""
    );
    if (response.kind === "ok") {
      setShowSuccessModal(true);
    } else {
      setShowFailModal(true);
    }
  }

  async function getFormEmailResponse(record: IFormResponse) {
    const response = await api.form.getFormEmailResponse(record?.id!);
    if (response.kind !== "ok") return;
    setSingleFormEmailResponse(response.data);
  }

  useEffect(() => {
    if (data) setTableData(data.data);
  }, [data]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icons.SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortingData = (item: {
    index: string;
    label: string;
  }): TableColumnProps<IFormResponse> => {
    let object: TableColumnProps<IFormResponse> = {};
    if (item.index === "name") {
      object = getColumnSearchProps(item.index);
    }
    if (item.index === "createDate") {
      object = {
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => {
          let first = new Date(a[item.index] ?? 0);
          let second = new Date(b[item.index] ?? 0);

          return first.getTime() - second.getTime();
        },
      };
    }
    return object;
  };

  const columnTitleComponent = columnTitle.map((title) => (
    <Table.Column
      title={title.label}
      key={title.index}
      dataIndex={title.index}
      {...sortingData(title)}
      render={(render) => {
        if (!render) return "null";
        if (title.index === "createDate")
          return dayjs(render).format("YYYY-MM-DD HH:mm:ss");
        return String(render);
      }}
    />
  ));

  return (
    <List
      pageHeaderProps={{
        title: defaultHeader(t("form:list.title")),
      }}
      createButtonProps={defaultCreateButtonProps}
      canCreate={false}
    >
      <Table
        rowKey="id"
        loading={isFetching}
        dataSource={tableData}
        showSorterTooltip={false}
      >
        {columnTitleComponent}
        <Table.Column<IFormResponse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Tooltip placement="top" title={t("buttons.show")}>
                  <Button
                    size="large"
                    icon={<EyeFilled />}
                    style={{
                      color: "blue",
                    }}
                    onClick={() => {
                      setTableToShowRecord(record);
                      setShowTableModal(true);
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.edit")}>
                  <Button
                    size="large"
                    icon={<EditFilled />}
                    style={{
                      color: "#0e6d33",
                    }}
                    onClick={() => {
                      setShowEditModal(true);
                      getFormEmailResponse(record);
                      setRecordToEdit(record);
                    }}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
      {showTableModal && (
        <TableModal
          callback={(e) => setShowTableModal(e)}
          visible={showTableModal}
          record={tableToShowRecord!}
        />
      )}
      {/* emailTo emailCc form */}
      <Modal
        title={`${recordToEdit ? recordToEdit.name : ""} ${t(
          "form:editModal.title"
        )}`}
        visible={showEditModal}
        onOk={async () => {
          if (!recordToEdit) return;
          editFormEmails();
          setShowEditModal(false);
        }}
        onCancel={() => {
          setRecordToEdit(null);
          setShowEditModal(false);
        }}
        footer={[
          <Button
            style={defaultButtonOutline}
            onClick={() => {
              setShowEditModal(false);
              setSingleFormEmailResponse(null);
            }}
          >
            {t("buttons.cancel")}
          </Button>,
          <Button
            style={defaultButtonFilled}
            onClick={() => {
              editFormEmails();
              setShowEditModal(false);
              setSingleFormEmailResponse(null);
            }}
          >
            {t("buttons.edit")}
          </Button>,
        ]}
      >
        {singleFormEmailResponse && (
          <Form
            layout="vertical"
            ref={formRef}
            initialValues={{
              name: singleFormEmailResponse?.name ?? "",
              to: singleFormEmailResponse?.emailTo ?? "",
              cc: singleFormEmailResponse?.emailCC ?? "",
            }}
          >
            <Form.Item name="name" label={t("label.name")}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="to"
              label={t("label.emailTo")}
              tooltip={t("tooltip.emailTo")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="cc"
              label={t("label.emailCC")}
              tooltip={t("tooltip.emailCC")}
            >
              <Input />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title={t("form:list.successModal.title")}
        visible={showSuccessModal}
        onOk={() => setShowSuccessModal(false)}
        onCancel={() => setShowSuccessModal(false)}
        cancelButtonProps={{
          hidden: true,
        }}
        okButtonProps={{
          style: defaultButtonFilled,
        }}
      >
        {t("form:list.successModal.title")}
      </Modal>
      <Modal
        title={t("form:list.warningModal.title")}
        cancelButtonProps={{
          hidden: true,
        }}
        visible={showFailModal}
        okButtonProps={{
          style: defaultButtonFilled,
        }}
        onOk={() => setShowFailModal(false)}
        onCancel={() => setShowFailModal(false)}
      >
        {t("form:list.warningModal.message")}
      </Modal>
    </List>
  );
};

import { CustomQuill } from "components/QuillEditor/CustomQuillEditor";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentFormTextEditorField({
    field,
    data,
    fieldChanged,
    location,
  }: {
    field: IComponentField;
    data: any;
    fieldChanged: FieldChangedCallback;
    location: string[];
  }) {
    return (
      <CustomQuill
        value={data ?? ''}
        setValue={(e) => fieldChanged([...location, field.name], e)}
      />
    );
  }
import { ComponentFormArrayField } from "./ComponentFormArrayField";
import { ComponentFormObjectField } from "./ComponentFormObjectField";
import { ComponentFormValueField } from "./ComponentFormValueField";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentFormField({
    field,
    data,
    fieldChanged,
    location,
  }: {
    field: IComponentField;
    data: any;
    fieldChanged: FieldChangedCallback;
    location: string[];
  }) {
    const currentData = data[field.name];

    switch (field.type) {
      case "array":
        return (
          <ComponentFormArrayField
            field={field}
            data={currentData}
            fieldChanged={fieldChanged}
            location={location}
          />
        );

      case "object":
        return (
          <ComponentFormObjectField
            field={field}
            data={currentData}
            fieldChanged={fieldChanged}
            location={location}
          />
        );

    case 'hidden':
        return <></>

      default:
        return (
          <ComponentFormValueField
            field={field}
            data={currentData}
            fieldChanged={fieldChanged}
            location={location}
          />
        );
    }
  }
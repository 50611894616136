const defaultHeader = (title: string) => (
  <h1
    style={{
      fontSize: "30px",
      fontWeight: "500",
    }}
  >
    {title}
  </h1>
);

export { defaultHeader };

import { Refine } from "@pankod/refine-core";
import {
  Layout,
  ReadyPage,
  notificationProvider,
  ErrorComponent,
} from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest/";
import "@pankod/refine-antd/dist/styles.min.css";
import { ProfilePage } from "./pages/profile";
import { authProvider } from "./auth/authProvider";
import { ITable, ResourceProps } from "interfaces";
import { useContext } from "react";
import {
  BsCode,
  BsFile,
  BsHeadset,
  BsHouse,
  BsParagraph,
  BsPersonCircle,
  BsShop,
  BsTag,
} from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { CustomMenu } from "customMenu";
import { IAuthContext, AuthContext } from "./AuthContext";
import { FormList } from "pages/form";
import api from "api/api";
import {
  CustomLoginPage,
  MetaCreate,
  MetaEdit,
  MetaList,
  CodeCreate,
  CodeList,
  CodeEdit,
  CreateModule,
  EditModule,
  ModuleList,
  PageList,
  PageCreate,
  HomePage,
  PageEdit,
  HeaderList,
  HeaderEdit,
} from "pages";
import { FooterEdit, FooterList } from "pages/footer";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { tables } = useContext(AuthContext) as IAuthContext;
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  function dataModuleParent() {
    let array: ResourceProps[] = [];
    if (tables) {
      const r = {
        name: "module",
        options: {
          label: t("sider:modules"),
        },
        icon: <BiCategory />,
      };
      array.push(r);
    }
    return array;
  }
  function dataModules() {
    let array: ResourceProps[] = [];
    if (tables) {
      tables?.map((e: ITable) => {
        const r: ResourceProps = {
          parentName: "module",
          name: `${e.id}`,
          options: {
            label: e.name,
            route: "tables" + e.id,
          },
          list: ModuleList,
          create: CreateModule,
          edit: EditModule,
        };
        array.push(r);
        return false;
      });
    }
    return array;
  }
  return (
    <Refine
      authProvider={authProvider}
      routerProvider={routerProvider}
      dataProvider={dataProvider(api.config.url, api.instance)}
      Layout={Layout}
      ReadyPage={ReadyPage}
      Sider={CustomMenu}
      notificationProvider={notificationProvider}
      catchAll={<ErrorComponent />}
      LoginPage={CustomLoginPage}
      i18nProvider={i18nProvider}
      warnWhenUnsavedChanges={true}
      resources={[
        {
          name: "app",
          icon: <BsHouse />,
          list: HomePage,
          options: {
            label: t("sider:home"),
          },
        },
        {
          name: "Profile",
          options: {
            label: t("sider:profile"),
          },
          icon: <BsPersonCircle />,
          list: ProfilePage,
        },
        ...dataModuleParent(),
        ...dataModules(),
        {
          name: "code",
          options: {
            label: t("sider:code"),
          },
          icon: <BsCode />,
          list: CodeList,
          create: CodeCreate,
          edit: CodeEdit,
        },
        {
          name: "page",
          options: {
            label: t("sider:page"),
          },
          icon: <BsFile />,
          list: PageList,
          create: PageCreate,
          edit: PageEdit,
        },
        {
          name: "header",
          options: {
            label: t("sider:header"),
          },
          icon: <BsHeadset />,
          list: HeaderList,
          edit: HeaderEdit,
        },
        {
          name: "footer",
          options: {
            label: t("sider:footer"),
          },
          icon: <BsShop />,
          list: FooterList,
          edit: FooterEdit,
        },
        {
          name: "meta",
          options: {
            label: t("sider:meta"),
          },
          icon: <BsTag />,
          list: MetaList,
          create: MetaCreate,
          edit: MetaEdit,
        },
        {
          name: "form",
          options: {
            label: t("sider:form"),
          },
          icon: <BsParagraph />,
          list: FormList,
          create: MetaCreate,
          edit: MetaEdit,
        },
      ]}
    />
  );
};
export default App;

import {
  Button,
  Edit,
  Form,
  FormInstance,
  Icons,
  message,
  Modal,
} from "@pankod/refine-antd";
import {
  useCustom,
  useNavigation,
  useResource,
  useTranslate,
} from "@pankod/refine-core";
import api from "api/api";
import {
  defaultButtonFilled18,
  defaultHeader,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
import { ComponentForm } from "components/ComponentForm/ComponentForm";
import {
  FieldChangedCallback,
  IComponentField,
} from "components/ComponentForm/interfaces";
import { createRef, useEffect, useState } from "react";
import _ from "lodash";

const { SaveTwoTone } = Icons;

type nestedMap = {
  [key: string]:
    | {
        [key: string]: any;
      }
    | any;
};
export const FooterEdit = () => {
  const t = useTranslate();
  const { id } = useResource();
  const formRef = createRef<FormInstance>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const nav = useNavigation();
  const { data, isFetching } = useCustom({
    url: `/layout-containers/${id}`,
    method: "get",
  });

  const [componentFields, setComponentFields] = useState<IComponentField[]>();
  const [formData, setFormData] = useState<nestedMap>({});

  useEffect(() => {
    async function fetchInitialValues() {
      const res = await api.layOut.getSingleLayout(id);
      if (res.kind === "ok" && res.data && res.data.componentData) {
        setFormData(res.data);
        const res2 = await api.pages.getComponentFields(res.data.componentId);
        if (res2.kind === "ok" && res2.data) {
          setComponentFields(res2.data);
        }
      }
    }
    if (id && data) fetchInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  async function showResponse() {
    var responseBody = {};
    responseBody["name"] = formRef.current?.getFieldValue("name");
    responseBody["isActive"] =
      formRef.current?.getFieldValue("isActive") ?? false;
    responseBody["type"] = "Component";

    responseBody["componentData"] = formData.componentData;

    const res = await api.layOut.patchLayout(id, responseBody);
    if (res.kind === "ok") {
      message.success(t("success.container.create"));
      setShowSuccess(true);
    }
    if (res.kind !== "ok") message.error(res.kind);
  }

  const fieldChanged: FieldChangedCallback = (
    location: string[],
    value: any
  ) => {
    const componentData = formData.componentData;
    const currentValues = { ...componentData };

    _.set(currentValues, location, value);

    setFormData({ ...formData, componentData: currentValues });
  };

  return (
    <Edit
      actionButtons={[
        <Button
          icon={<SaveTwoTone color="white" twoToneColor="white" />}
          style={defaultButtonFilled18}
          onClick={() => {
            showResponse();
          }}
        >
          {t("buttons.save")}
        </Button>,
      ]}
      canDelete={false}
      pageHeaderProps={{
        extra: null,
        onBack: () => setShowWarningModal(true),
        title: defaultHeader(t("footer:edit.title")),
      }}
      isLoading={isFetching}
    >
      <style>
        {`
          .ant-space-item{
            width : 100%;
        
          }`}
      </style>
      {formData && componentFields && (
        <Form layout="vertical" ref={formRef}>
          <InputComponent
            initialValue={formData["name"]}
            name="name"
            label={t("table.name")}
            index="text"
          />
          <InputComponent
            initialValue={formData["isActive"]}
            name="isActive"
            label={t("table.isActive")}
            index="bool"
          />
          <ComponentForm
            data={formData.componentData}
            fields={componentFields}
            fieldChanged={fieldChanged}
          />
        </Form>
      )}
      <Modal
        visible={showSuccess}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
        title={t("footer:successModal.title")}
      >
        <p>{t("footer:successModal.message")}</p>
      </Modal>
      <Modal
        closable
        title={t("footer:warningModal.title")}
        visible={showWarningModal}
        onOk={() => nav.goBack()}
        onCancel={() => setShowWarningModal(false)}
        footer={[
          <DefaultModalCancelButton
            onClick={() => setShowWarningModal(false)}
          />,
          <DefaultModalOkayButton onClick={() => nav.goBack()} />,
        ]}
      >
        <p>{t("footer:warningModal.message")}</p>
      </Modal>
    </Edit>
  );
};

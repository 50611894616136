import { Input } from "@pankod/refine-antd";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentFormMultiLineTextField({
    field,
    data,
    fieldChanged,
    location,
  }: {
    field: IComponentField;
    data: any;
    fieldChanged: FieldChangedCallback;
    location: string[];
  }) {
    return (
      <Input.TextArea
        rows={4}
        value={data ?? ''}
        onChange={(e) => fieldChanged([...location, field.name], e.target.value)}
      />
    );
  }
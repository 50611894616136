import {
  Button,
  Icons,
  Input,
  message,
  Modal,
  Space,
  Table,
  TableColumnProps,
  Tooltip,
} from "@pankod/refine-antd";
import api from "api/api";
import {
  defaultButtonFilled18,
  defaultButtonFilled18Disabled,
} from "components";
import { IPageContainerResponse } from "interfaces";
import { useContext, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { ContainerContext, IContainerContext } from "./ContainerContext";
import { useTranslate } from "@pankod/refine-core";
const { EditFilled, DeleteFilled } = Icons;

export const ContainerList = () => {
  const t = useTranslate();
  const { handlePageChange, recordToShow, page, handleRecordToChange } =
    useContext(ContainerContext) as IContainerContext;
  const [listOfContainers, setListOfContainers] = useState<
    IPageContainerResponse[] | null
  >(null);
  const idToDelete = useRef<string | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getContainers() {
      setLoading(() => true);
      const res = await api.pages.getPageContainers(recordToShow?.id);
      if (res.kind === "ok") setListOfContainers(res.data);
      setLoading(() => false);
    }
    if (recordToShow) getContainers();
  }, [recordToShow, page, refetch]);

  async function handleDelete() {
    setShowDeleteConfirmation(false);
    if (idToDelete.current === null) return;
    const res = await api.pages.deletePageContainer(
      recordToShow?.id,
      idToDelete.current
    );
    if (res.kind === "ok") {
      setRefetch((old) => old + 1);
      idToDelete.current = null;
      message.success(t("success.component.delete"));
    } else {
      message.error(res.kind);
    }
  }

  // search
  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }

  type DataIndex = keyof DataType;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icons.SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortingData = (
    item: string
  ): TableColumnProps<IPageContainerResponse> => {
    let object: TableColumnProps<IPageContainerResponse> = {};
    switch (item) {
      case "ID":
        break;
      case "name":
      case "Link":
        object = getColumnSearchProps(item);
        break;
      case "isActive":
      case "disabled":
      case "type":
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = a[item] ?? "z";
            let second = b[item] ?? "z";
            return String(first).localeCompare(String(second));
          },
        };
        break;
      default:
        break;
    }
    return object;
  };

  return (
    <>
      {recordToShow && (
        <Button
          style={
            !recordToShow.isActive || recordToShow.disabled
              ? {
                  float: "right",
                  marginBottom: "10px",
                  ...defaultButtonFilled18Disabled,
                }
              : {
                  float: "right",
                  marginBottom: "10px",
                  ...defaultButtonFilled18,
                }
          }
          disabled={!recordToShow.isActive || recordToShow.disabled}
          onClick={() => handlePageChange(2)}
        >
          {t("buttons.create")}
        </Button>
      )}
      {recordToShow && (
        <Table
          dataSource={listOfContainers ? listOfContainers : []}
          loading={loading}
        >
          <Table.Column
            title={t("table.id")}
            dataIndex="id"
            render={(render) => {
              if (!render) return "-";
              return String(render);
            }}
          />

          <Table.Column
            title={t("table.name")}
            dataIndex="name"
            {...sortingData("name")}
            render={(render) => {
              if (!render) return "-";
              return String(render);
            }}
          />
          <Table.Column
            title={t("table.type")}
            dataIndex="type"
            {...sortingData("type")}
            render={(render) => {
              if (!render) return "-";
              return String(render);
            }}
          />
          <Table.Column
            title={t("table.isActive")}
            dataIndex="isActive"
            {...sortingData("isActive")}
            render={(render) => {
              if (!render) return "false";
              return String(render);
            }}
          />
          <Table.Column
            title={t("table.isDisabled")}
            dataIndex="disabled"
            {...sortingData("disabled")}
            render={(render) => {
              if (!render) return "-";
              return String(render);
            }}
          />
          <Table.Column<IPageContainerResponse>
            title={t("table.actions")}
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <Tooltip placement="top" title={t("buttons.edit")}>
                    <Button
                      size="large"
                      icon={
                        <EditFilled
                          style={{
                            color:
                              !recordToShow.isActive || record.disabled
                                ? "grey"
                                : "#0e6d33",
                          }}
                        />
                      }
                      disabled={!recordToShow.isActive || record.disabled}
                      onClick={() => {
                        handleRecordToChange(record.id);
                        handlePageChange(0);
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={t("buttons.delete")}>
                    <Button
                      icon={
                        <DeleteFilled
                          style={{
                            color:
                              !recordToShow.isActive || record.disabled
                                ? "grey"
                                : "#ff4343",
                          }}
                        />
                      }
                      disabled={
                        recordToShow
                          ? !recordToShow.isActive || record.disabled
                          : true
                      }
                      size="large"
                      onClick={() => {
                        idToDelete.current = String(record.id);
                        setShowDeleteConfirmation(true);
                      }}
                    />
                  </Tooltip>
                </Space>
              );
            }}
          />
        </Table>
      )}
      <Modal
        visible={showDeleteConfirmation}
        title={t("pages:container.list.deleteModal.title")}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        onOk={handleDelete}
        closable
        okText={t("buttons.ok")}
        cancelText={t("buttons.cancel")}
      >
        <p>{t("pages:container.list.deleteModal.message")}</p>
      </Modal>
    </>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";

export const locales = [
  {
    label: "Türkçe",
    code: "tr",
    icon: "🇹🇷",
  },
  {
    label: "English",
    code: "en",
    icon: "🇺🇸",
  },
];

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: locales.map((locale) => locale.code),
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [
      "common",
      "home",
      "profile",
      "code",
      "pages",
      "header",
      "footer",
      "meta",
      "form",
      "login",
      "loopable",
      "sider",
      "component",
      "module",
    ],
    defaultNS: "common",
    fallbackLng: ["tr", "en"],
  });

export default i18n;

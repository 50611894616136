import {
  Button,
  Icons,
  Input,
  Modal,
  Table,
  Typography,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import "./tableStyle.scss";
import { IFormDatas, IFormResponse } from "interfaces";
import { ChangeEvent, useEffect, useState } from "react";
import { buttonStyle } from "styleObjects";
import * as XLSX from "xlsx";
import { defaultButtonFilled } from "components";
import moment from "moment";

const { SearchOutlined, ExportOutlined } = Icons;
type Prop = {
  record: IFormResponse;
  visible: boolean;
  callback: (e: boolean) => void;
};
type Prop2 = {
  [key: string]: string;
};
export const TableModal = ({ record, visible, callback }: Prop) => {
  const t = useTranslate();
  const [tableData, setTableData] = useState<IFormDatas | null>();
  const [searchField, setSearchField] = useState("");
  const [filteredTableData, setFilteredTableData] = useState<Prop2[]>([]);
  const { data, isFetching } = useCustom<IFormDatas>({
    url: `forms/${record.id}/datas`,
    method: "get",
  });
  function searchFieldChange(e: ChangeEvent<HTMLInputElement>) {
    let value = e.target.value;
    setSearchField(e.target.value);
    if (value) {
      value = value.toLowerCase();
      var filteredData = tableData?.datas.filter((item) => {
        var isFound = false;
        for (var key in item) {
          isFound = item[key] ? item[key].toLowerCase().includes(value) : false;
          if (isFound) return true;
        }
        return isFound;
      });
      setFilteredTableData(filteredData ?? []);
    }
  }

  async function exportXLS() {
    if (!tableData?.datas) return;
    const worksheet = XLSX.utils.json_to_sheet(tableData?.datas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${record.name ?? "DataExport"} ${moment().format("YYYY-MM-DD")}.xlsx`
    );
  }

  useEffect(() => {
    if (data) setTableData(data.data);
  }, [data]);

  return (
    <Modal
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <Typography.Text>{record.name}</Typography.Text>
          <Input
            prefix={<SearchOutlined />}
            style={{
              width: "inherit",
            }}
            placeholder={t("search")}
            value={searchField}
            onChange={searchFieldChange}
          />
        </div>
      }
      width={"80vw"}
      visible={visible}
      footer={[
        <div className="horz">
          <Button
            onClick={exportXLS}
            style={buttonStyle}
            icon={<ExportOutlined />}
          />
          <div className="fg" />
          <Button onClick={() => callback(false)} style={defaultButtonFilled}>
            {t("buttons.close")}
          </Button>
        </div>,
      ]}
      onCancel={() => callback(false)}
    >
      {tableData && (
        <Table
          loading={isFetching}
          dataSource={searchField ? filteredTableData : tableData.datas}
        >
          {tableData.fields.map((item) => (
            <Table.Column
              title={item.label}
              dataIndex={item.name}
              key={item.label}
              width={Math.max(11 * item.label.length, 100)}
              ellipsis={true}
            />
          ))}
        </Table>
      )}
    </Modal>
  );
};

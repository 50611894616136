import { Icons, message, Upload, UploadProps } from "@pankod/refine-antd";
import api from "api/api";
import { loadAuthData } from "auth/auth";
import { AuthContext, IAuthContext } from "AuthContext";
import { defaultIconColor } from "components/commonStyles";
import { useContext } from "react";
import { FieldChangedCallback, IComponentField } from "./interfaces";
import { useTranslate } from "@pankod/refine-core";

export function ComponentFormImageField({
  field,
  data,
  fieldChanged,
  location,
}: {
  field: IComponentField;
  data: any;
  fieldChanged: FieldChangedCallback;
  location: string[];
}) {
  const t = useTranslate();
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const { accessToken } = loadAuthData();

  const save = (value: string | null) =>
    fieldChanged([...location, field.name], value);

  const uploadProps = () => {
    const p: UploadProps = {
      name: "file",
      multiple: false,
      accept: "image/png, image/jpeg",
      showUploadList: {
        showDownloadIcon: false,
      },
      onRemove: async () => {
        save(null);
      },
      defaultFileList: data
        ? [
            {
              uid: "-1",
              name: "uploaded image.png",
              status: "done",
              url: data,
            },
          ]
        : [],
      maxCount: 1,
      customRequest: async (info: any) => {
        const { onSuccess, onError, file } = info;
        let form = new FormData();
        form.append("file", file);
        const response = await api.file.uploadFile(form);
        if (response.kind === "ok") {
          save(response.data.url);
          message.success(t("success.upload"));
          onSuccess!(t("success.upload"));
        } else {
          const err = new Error(t("error.upload"));
          message.error(t("error.upload"));
          onError!(err);
        }
      },
      beforeUpload: () => {
        // if you change your minds about rules
        return true;
      },
      headers: {
        "Accept-Language": languageId?.toString() ?? "1",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return p;
  };

  return (
    <Upload.Dragger {...uploadProps()}>
      <p className="ant-upload-drag-icon">
        <Icons.InboxOutlined style={defaultIconColor} />
      </p>
      <p className="ant-upload-hint">{t("component:dropzone")}</p>
      {data && (
        <>
          <img
            alt="uploaded"
            src={data}
            style={{
              width: "auto",
              height: "100%",
              maxWidth: "80%",
              maxHeight: "400px",
              objectFit: "cover",
            }}
          />
        </>
      )}
    </Upload.Dragger>
  );
}

import { Button, Form, Icons, Input } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { IarrtObject, IMetaPayload } from "interfaces";
import { buttonw1002, horizontalStyle } from "styleObjects";
const { PlusCircleFilled } = Icons;

type Prop = {
  item: IarrtObject;
  initialValues: IMetaPayload | null;
  callBack: (e: IarrtObject[]) => void;
  attrCount: IarrtObject[];
};

export const ArrtibuteForm = ({
  item,
  initialValues,
  callBack,
  attrCount,
}: Prop) => {
  const t = useTranslate();
  return (
    <>
      <div key={item.itemKey} style={horizontalStyle}>
        <Form.Item
          label={t("label.name")}
          name={`arrtName${item.itemKey}`}
          initialValue={
            initialValues
              ? initialValues.attributes
                ? item.itemKey < initialValues.attributes.length
                  ? initialValues.attributes![item.itemKey].name
                  : ""
                : ""
              : ""
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("label.value")}
          name={`arrtValue${item.itemKey}`}
          initialValue={
            initialValues
              ? initialValues.attributes
                ? item.itemKey < initialValues.attributes.length
                  ? initialValues.attributes![item.itemKey].value
                  : ""
                : ""
              : ""
          }
        >
          <Input />
        </Form.Item>
      </div>
      <Button
        key={"b" + item.itemKey}
        icon={<PlusCircleFilled />}
        style={buttonw1002}
        onClick={() => {
          callBack(attrCount.filter((e) => e.itemKey !== item.itemKey));
        }}
      >
        {t("buttons.delete")}
      </Button>
    </>
  );
};

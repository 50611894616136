import { useState, CSSProperties, useEffect, useContext } from "react";
import {
  ITreeMenu,
  CanAccess,
  useRefineContext,
  useRouterContext,
  useLogout,
  useGetLocale,
  useIsAuthenticated,
  useSetLocale,
  useTranslate,
} from "@pankod/refine-core";
import {
  AntdLayout,
  Menu,
  Grid,
  Icons,
  useMenu,
  Modal,
  Button,
  Dropdown,
  Space,
  Avatar,
} from "@pankod/refine-antd";
import { IAuthContext, AuthContext } from "./AuthContext";
import api from "api/api";
import { locales } from "i18n";
const { DownOutlined } = Icons;

export const CustomMenu: React.FC = () => {
  const t = useTranslate();
  const { Link } = useRouterContext();
  const { hasDashboard } = useRefineContext();
  const { SubMenu } = Menu;
  const { mutate: logout } = useLogout();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const [showMenu, setShowMenu] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const locale = useGetLocale();
  const currentLocale = locale();
  const setLocale = useSetLocale();
  const auth = useIsAuthenticated();
  const { languageId, updateLanguageId, getTables, profilePicture } =
    useContext(AuthContext) as IAuthContext;
  type userDataResponse = {
    email: string;
    favicon: string;
    logo: string;
    supportedLanguages: {
      id: number;
      image: string;
      name: string;
    }[];
  };
  const [axiosResponse, setAxiosResponse] = useState<userDataResponse | null>();

  useEffect(() => {
    async function getUserData() {
      if (!auth) return;
      await getTables();
      const response = await api.app.getMyApp();
      if (response.kind !== "ok") return;
      const { data } = response;
      if (
        data.supportedLanguages.some((item: any) => item.id === languageId) ===
        false
      )
        updateLanguageId(data.supportedLanguages[0].id);
      setAxiosResponse(data);
    }
    if (auth) getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
  const menu = (
    <Menu selectedKeys={`${languageId}` ? [`${languageId}`] : []}>
      {axiosResponse
        ? axiosResponse.supportedLanguages.map((lang) => (
            <Menu.Item
              key={lang.id}
              onClick={() => updateLanguageId(lang.id)}
              icon={
                <span style={{ marginRight: 8 }}>
                  <Avatar size={16} src={lang.image} />
                </span>
              }
              style={{
                fontSize: "18px",
              }}
            >
              {lang.name}
            </Menu.Item>
          ))
        : null}
    </Menu>
  );
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item;

      if (children.length > 0) {
        return (
          <SubMenu
            key={route}
            icon={icon ?? <Icons.UnorderedListOutlined />}
            title={label}
          >
            {renderTreeView(children, selectedKey)}
          </SubMenu>
        );
      }
      const isSelected = route === selectedKey;
      const isRoute = !(parentName !== undefined && children.length === 0);
      return (
        <CanAccess key={route} resource={name.toLowerCase()} action="list">
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? "bold" : "normal",
            }}
            icon={icon ?? (isRoute && <Icons.UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      );
    });
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {/* <RenderToTitle collapsed={collapsed} /> */}
      <Menu
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true);
          }
        }}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Menu.Item
          style={{
            margin: "20px 0 10px 0",
            height: "100px",
          }}
        >
          {axiosResponse && (
            <Avatar
              shape="square"
              src={profilePicture}
              style={{
                width: "100%",
                maxWidth: "169px",
                height: "100px",
                objectFit: "contain",
              }}
            />
          )}
        </Menu.Item>

        {hasDashboard ? (
          <Menu.Item
            key="dashboard"
            style={{
              fontWeight: selectedKey === "/" ? "bold" : "normal",
            }}
            icon={<Icons.DashboardOutlined />}
          >
            <Link to="/">Dashboard</Link>
            {!collapsed && selectedKey === "/" && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        ) : null}
        {renderTreeView(menuItems, selectedKey)}
        <div
          style={{
            flexGrow: "1",
            minHeight: "40px",
          }}
        />
        <Menu.Item
          style={{
            fontWeight: selectedKey === "/" ? "bold" : "normal",
          }}
          icon={locales.find((loc) => loc.code === currentLocale)?.icon}
        >
          <Dropdown
            overlay={
              <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
                {locales.map((loc) => (
                  <Menu.Item
                    key={loc.code}
                    onClick={() => setLocale(loc.code)}
                    icon={<span style={{ marginRight: 8 }}>{loc.icon}</span>}
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {loc.label}
                  </Menu.Item>
                ))}
              </Menu>
            }
            className="dropd"
          >
            <Button
              type="link"
              style={{
                padding: "0px",
              }}
            >
              <Space
                style={{
                  color: "white",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              >
                {locales.find((loc) => loc.code === currentLocale)?.label}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Menu.Item>

        <Menu.Item
          style={{
            fontWeight: selectedKey === "/" ? "bold" : "normal",
          }}
          icon={
            <Avatar
              size={16}
              src={
                axiosResponse
                  ? axiosResponse.supportedLanguages.filter(
                      (item) => item.id === languageId
                    )[0].image
                  : ""
              }
            />
          }
        >
          <Dropdown overlay={menu} className="dropd">
            <Button
              type="link"
              style={{
                padding: "0px",
              }}
            >
              <Space
                style={{
                  color: "white",
                  fontSize: "18px",
                }}
              >
                {axiosResponse
                  ? axiosResponse.supportedLanguages.filter(
                      (item) => item.id === languageId
                    )[0].name
                  : ""}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Menu.Item>
        <Menu.Item
          style={{
            fontWeight: selectedKey === "/" ? "bold" : "normal",
          }}
          icon={<Icons.LogoutOutlined />}
        >
          <div onClick={() => setShowMenu(true)}>{t("sider:signOut")}</div>
        </Menu.Item>
      </Menu>
      <Modal
        title={t("sider:modal.title")}
        visible={showMenu}
        onOk={() => {
          logout();
        }}
        footer={[
          <Button key="back" onClick={() => setShowMenu(false)}>
            {t("buttons.cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={() => logout()}>
            {t("sider:signOut")}
          </Button>,
        ]}
        onCancel={() => setShowMenu(false)}
      >
        <div>{t("sider:modal.message")}</div>
      </Modal>
    </AntdLayout.Sider>
  );
};

const antLayoutSider: CSSProperties = {
  // position: "relative",
};
const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 999,
};

import {
  Button,
  Create,
  Form,
  FormInstance,
  Icons,
  Input,
  message,
  Modal,
} from "@pankod/refine-antd";
import { useNavigation, useResource, useTranslate } from "@pankod/refine-core";
import { createRef, useContext, useEffect, useState } from "react";
import {
  defaultButtonFilled18,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
import { AuthContext, IAuthContext } from "AuthContext";
import api from "api/api";
import { IPageCreatePayLoad } from "interfaces";
const { SaveTwoTone, LinkOutlined } = Icons;

export const PageEdit = () => {
  const t = useTranslate();
  const { id } = useResource();
  const formRef = createRef<FormInstance>();
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const [showBackWarningModal, setShowBackWarningModal] = useState(false);
  const [initialValues, setInitialValues] = useState<IPageCreatePayLoad>();
  const nav = useNavigation();

  async function createPageFunciton() {
    try {
      await formRef.current?.validateFields();
      const payLoad: IPageCreatePayLoad = {
        name: formRef.current?.getFieldValue("name") ?? null,
        description: formRef.current?.getFieldValue("description") ?? null,
        link: formRef.current?.getFieldValue("link") ?? null,
        image: formRef.current?.getFieldValue("image") ?? null,
        isActive: true,
      };
      const res = id
        ? await api.pages.updatePage(payLoad, id)
        : await api.pages.createPage(payLoad, languageId ?? 1);
      if (res.kind === "ok") {
        setshowSuccessModal(true);
      } else {
        message.error(res.kind);
      }
    } catch (error) {
      message.warn(t("error.fillRequired"));
    }
  }

  useEffect(() => {
    async function fetchInitialValues() {
      const res = await api.pages.getInitialValuesPage(id);
      if (res.kind === "ok") {
        setInitialValues(res.data);
      }
    }

    if (id) fetchInitialValues();
  }, [id]);

  return (
    <Create
      title={t("pages:edit.title")}
      actionButtons={[
        <Button
          icon={<SaveTwoTone color="white" twoToneColor="white" />}
          style={defaultButtonFilled18}
          onClick={createPageFunciton}
        >
          {t("buttons.save")}
        </Button>,
      ]}
      pageHeaderProps={{ onBack: () => setShowBackWarningModal(true) }}
    >
      {(!id || initialValues) && (
        <Form layout="vertical" ref={formRef}>
          <InputComponent
            label={t("label.name")}
            name="name"
            index="text"
            rules={[
              {
                required: true,
                message: t("error.name.required"),
              },
            ]}
            initialValue={initialValues ? initialValues.name : ""}
          />
          <InputComponent
            label={t("label.image")}
            name="image"
            index="file"
            initialValue={initialValues ? initialValues.image : ""}
          />
          <InputComponent
            label={t("label.description")}
            name="description"
            index="multi-line-text"
            rules={[
              {
                required: true,
                message: t("error.description.required"),
              },
            ]}
            initialValue={initialValues ? initialValues.name : ""}
          />
          <Form.Item
            label={t("label.link")}
            rules={[
              {
                required: true,
                message: t("error.link.required"),
              },
            ]}
            name="link"
            initialValue={initialValues ? initialValues.link : ""}
          >
            <Input prefix={<LinkOutlined />} />
          </Form.Item>
        </Form>
      )}
      <Modal
        closable
        title={t("pages:edit.successModal.title")}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
        visible={showSuccessModal}
      >
        {t("pages:edit.successModal.message")}
      </Modal>
      <Modal
        closable
        title={t("pages:edit.warningModal.title")}
        onCancel={() => setShowBackWarningModal(false)}
        footer={[
          <DefaultModalCancelButton
            onClick={() => setShowBackWarningModal(false)}
          />,
          <DefaultModalOkayButton onClick={() => nav.goBack()} />,
        ]}
        visible={showBackWarningModal}
      >
        {t("pages:edit.warningModal.message")}
      </Modal>
    </Create>
  );
};

import {
  Button,
  EditButton,
  Icons,
  Input,
  List,
  Modal,
  Space,
  Table,
  Tooltip,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { ILayOutListResponse } from "interfaces";
import { useContext, useRef, useState } from "react";
import { defaultCreateButtonProps, defaultHeader } from "components";
import { AuthContext, IAuthContext } from "AuthContext";
import DOMPurify from "dompurify";
import Highlighter from "react-highlight-words";
const { EditFilled, EyeFilled } = Icons;

export const FooterList = () => {
  const t = useTranslate();
  const columnTitle = [
    { text: t("table.id"), keyName: "id" },
    { text: t("table.name"), keyName: "name" },
    { text: t("table.type"), keyName: "type" },
    { text: t("table.isActive"), keyName: "isActive" },
    { text: t("table.isDisabled"), keyName: "isDisabled" },
  ];
  const [showRichText, setShowRichText] = useState(false);
  const [richData, setRichData] = useState<string | undefined>("");
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const { data, isFetching } = useCustom<ILayOutListResponse[]>({
    url: `/layout-containers`,
    method: "get",
    config: {
      filters: [
        {
          field: "footerOrHeader",
          operator: "eq",
          value: "footer",
        },
        {
          field: "languageId",
          operator: "eq",
          value: languageId,
        },
      ],
    },
  });

  // search
  // search
  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }

  type DataIndex = keyof DataType;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icons.SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columnTitleComponent = columnTitle.map((title) => {
    let extraObj =
      title.text === t("table.name") || title.text === t("table.type")
        ? getColumnSearchProps(title.keyName)
        : {};

    return (
      <Table.Column
        title={title.text}
        key={title.text}
        dataIndex={title.keyName}
        {...extraObj}
        render={(render, record: ILayOutListResponse) => {
          if (render === null) return "null";
          if (typeof render === "boolean")
            return String(record.isActive && !record.isDisabled);
          return String(render);
        }}
      />
    );
  });
  return (
    <List
      pageHeaderProps={{
        title: defaultHeader(t("footer:list.title")),
      }}
      createButtonProps={defaultCreateButtonProps}
      canCreate={false}
    >
      <Table
        rowKey="id"
        loading={isFetching}
        dataSource={data ? data.data : []}
        showSorterTooltip={false}
      >
        {columnTitleComponent}
        <Table.Column<ILayOutListResponse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Tooltip placement="top" title={t("table.preview")}>
                  <Button
                    size="large"
                    icon={<EyeFilled />}
                    style={{
                      color:
                        !record.isActive || record.isDisabled ? "grey" : "blue",
                    }}
                    onClick={() => {
                      if (!record) return;
                      setShowRichText(true);
                      let html = "";
                      // do some magic
                      function magicFunction(inputObj: any) {
                        for (var key in inputObj) {
                          if (typeof inputObj[key] === "object") {
                            html += `<p>${key} : <ul>`;
                            magicFunction(inputObj[key]);
                            html += "</ul> </p>";
                          } else {
                            html += `<li>${[key]} : ${inputObj[key]}</li>`;
                          }
                        }
                      }
                      for (let key in record) {
                        console.log(record[key]);
                        if (typeof record[key] === "object") {
                          html += `<p>${key} : <ul>`;
                          magicFunction(record[key]);
                          html += "</ul> </p>";
                        } else {
                          html += `<p>${key} : ${record[key]}<p/>`;
                        }
                      }
                      setRichData(html);
                    }}
                    disabled={!record.isActive || record.isDisabled}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.edit")}>
                  <EditButton
                    size="large"
                    icon={<EditFilled />}
                    style={{
                      color:
                        !record.isActive || record.isDisabled
                          ? "grey"
                          : "#0e6d33",
                    }}
                    hideText
                    recordItemId={record.id!}
                    disabled={!record.isActive || record.isDisabled}
                    // onMouseEnter={() => setshowTool(true)}
                    // onMouseLeave={() => setshowTool(false)}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        title={t("footer:richTextModal.title")}
        visible={showRichText}
        onOk={() => {
          setShowRichText(false);
        }}
        onCancel={() => setShowRichText(false)}
        okText={t("buttons.ok")}
        cancelText={t("buttons.cancel")}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(richData ?? ""),
          }}
        ></div>
      </Modal>
    </List>
  );
};

import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from "axios";
import {
  checkIsTokenExpired,
  loadAuthData,
  loadAuthToken,
  saveAuthData,
} from "auth/auth";
import { AppApi } from "./collections/app-api";
import { AuthApi } from "./collections/auth-api";
import { TablesApi } from "./collections/tables-api";
import { MetaTagsApi } from "./collections/metatags-api";
import { UsersApi } from "./collections/users-api";
import { PagesApi } from "./collections/pages-api";
import { FormsApi } from "./collections/forms-api";
import { CodeSnippetsApi } from "./collections/codeSnippets-api";
import { FileApi } from "./collections/file-api";
import { LayoutAPI } from "./collections/layout-api";
import axiosRetry from "axios-retry";
export class Api {
  instance!: AxiosInstance;
  config!: ApiConfig;
  sendingAuth: boolean = false;
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.setup();
  }

  setup() {
    const api = axios.create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
        "Accepted-Language": "1",
      },
    });
    axiosRetry(axios, {
      retries: 3, // number of retries
      retryDelay: (retryCount) => {
        const { accessToken, accessTokenExpire } = loadAuthData();
        console.log(
          `retry attempt: ${retryCount}`,
          accessToken,
          accessTokenExpire
        );
        return retryCount * 500; // time interval between retries
      },
      retryCondition: (error: AxiosError) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response?.status === 401;
      },
    });
    api.interceptors.request.use(
      (config) => {
        config.baseURL = this.config.url;
        config.headers!["Content-Type"] = "application/json";
        config.headers!["X-Requested-With"] = "XMLHttpRequest";
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );
    // seperate from the first one to not overwhealm
    api.interceptors.request.use(async (req: AxiosRequestConfig) => {
      if (req.url === "/Authentication/login") return req;
      const isExpired = checkIsTokenExpired();
      if (!isExpired) return req;
      console.log("====================================");
      console.log(isExpired, this.sendingAuth);
      console.log("====================================");
      if (this.sendingAuth) {
        return req;
      } else {
        this.sendingAuth = true;
        const authData = loadAuthData();
        const response = await this.instance
          .post(`/authentication/refresh-token`, {
            accessToken: authData.accessToken,
            refreshToken: authData.refreshToken,
          })
          .then((response) => {
            this.sendingAuth = false;

            console.log("====================================");
            console.log("setting to false", this.sendingAuth);
            console.log("====================================");
            return response;
          });

        if (response.status !== 200) return req;
        this.createToken(response.data.accessToken);
        saveAuthData(response.data);
        return req;
      }
    });
    const authToken = loadAuthToken();
    if (authToken)
      api.defaults.headers!["Authorization"] = "Bearer " + authToken;
    this.instance = api;
  }

  createToken(response: any) {
    this.instance.defaults.headers!["Authorization"] = "Bearer " + response;
  }

  setLanguageHeader(id: number) {
    this.instance.interceptors.request.use((config) => {
      config.headers!["Accepted-Language"] = id;
      return config;
    });
  }

  get app() {
    return new AppApi(this);
  }

  get file() {
    return new FileApi(this);
  }
  get form() {
    return new FormsApi(this);
  }

  get auth() {
    return new AuthApi(this);
  }

  get tables() {
    return new TablesApi(this);
  }
  get metaTags() {
    return new MetaTagsApi(this);
  }
  get users() {
    return new UsersApi(this);
  }
  get pages() {
    return new PagesApi(this);
  }
  get codeSnippets() {
    return new CodeSnippetsApi(this);
  }

  get layOut() {
    return new LayoutAPI(this);
  }
}

export default new Api();

import { message } from "@pankod/refine-antd";
import { AuthProvider } from "@pankod/refine-core";
import api from "api/api";
import {
  checkIsTokenExpired,
  clearAuthData,
  loadAuthData,
  saveAuthData,
} from "auth/auth";
import i18n from "../i18n";

export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    message.loading(i18n.t("loading.login"), 20);
    const response = await api.auth.login(email, password);
    if (response.kind !== "ok") {
      message.destroy();
      message.error(i18n.t("error.goingWrong"));
      return Promise.reject();
    }
    message.destroy();
    api.createToken(response.data.accessToken);
    saveAuthData(response.data);
    message.success(i18n.t("success.login"));
  },
  logout: () => {
    clearAuthData();
    message.success(i18n.t("success.logout"));
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const authData = loadAuthData();
    const { accessToken, accessTokenExpire, refreshToken, refreshTokenExpire } =
      authData;
    if (
      !accessToken ||
      !accessTokenExpire ||
      !refreshToken ||
      !refreshTokenExpire
    ) {
      return Promise.reject({ redirectPath: "/login" });
    }

    const isTokenExpired = checkIsTokenExpired();
    console.log(isTokenExpired);

    if (isTokenExpired) {
      return Promise.reject({ redirectPath: "/login" });
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: () => Promise.resolve(),
};

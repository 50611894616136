import { ComponentFormField } from "./ComponentFormField";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentForm({
    fields,
    fieldChanged,
    data = {}
  }: {
    fields: IComponentField[];
    fieldChanged: FieldChangedCallback;
    data: any;
  }) {
    return (
      <>
        {fields.map((field) => (
          <ComponentFormField
            field={field}
            data={data}
            key={field.name}
            fieldChanged={fieldChanged}
            location={[]}
          />
        ))}
      </>
    );
  }

import { Button } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { CSSProperties } from "react";

const defaultCreateButtonProps = {
  style: {
    color: "white",
    background: "#2a132e",
  },
};

const defaultButtonOutline: CSSProperties = {
  border: "1px solid rgb(42, 19, 46)",
  color: "rgb(42, 19, 46)",
};

const defaultButtonFilled: CSSProperties = {
  background: "rgb(42, 19, 46)",
  color: "white",
  fontSize: "16px",
  height: "34px",
};
const defaultOkayButton: CSSProperties = {
  fontSize: "16px",
  height: "34px",
};
const defaultButtonFilled18: CSSProperties = {
  background: "rgb(42, 19, 46)",
  color: "white",
  fontSize: "18px",
  height: "38px",
};

const defaultButtonFilled18Disabled: CSSProperties = {
  background: "grey",
  color: "white",
  fontSize: "18px",
  height: "38px",
};

export const DefaultModalCancelButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const t = useTranslate();
  return <Button onClick={onClick}>{t("buttons.no")}</Button>;
};

export const DefaultModalOkayButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const t = useTranslate();
  return (
    <Button type="primary" onClick={onClick}>
      {t("buttons.yes")}
    </Button>
  );
};

const defaultIconColor: CSSProperties = {
  color: "rgb(42, 19, 46)",
};

export {
  defaultIconColor,
  defaultCreateButtonProps,
  defaultButtonOutline,
  defaultButtonFilled,
  defaultButtonFilled18,
  defaultButtonFilled18Disabled,
  defaultOkayButton,
};

import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Show,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import api from "api/api";
import { defaultHeader } from "components";

import { IProfile } from "interfaces";
import React, { createRef, useState } from "react";

import "./profile.scss";

export const ProfilePage: React.FC = () => {
  const t = useTranslate();
  const formRef = createRef<FormInstance>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { data, isLoading, refetch } = useCustom<IProfile>({
    url: "/users/current",
    method: "get",
  });
  const [editing, setEditing] = useState(false);

  async function handleEdit() {
    if (!editing) {
      setEditing(true);
    } else {
      if (
        data?.data.email === formRef.current?.getFieldValue("email") &&
        data?.data.userName === formRef.current?.getFieldValue("username")
      ) {
        setEditing(false);
        return;
      }
      if (/\s/.test(formRef.current?.getFieldValue("username"))) {
        formRef.current?.setFields([
          {
            name: "username",
            errors: [t("error.username.space")],
          },
        ]);
      } else {
        setShowWarningModal(true);
      }
    }
  }

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      pageHeaderProps={{
        title: defaultHeader(t("profile:title")),
        extra: null,
      }}
    >
      <div className="ProfilePage">
        <Form layout="vertical" ref={formRef}>
          {data ? (
            <>
              <Form.Item
                name="id"
                label={t("label.id")}
                initialValue={data?.data.id ?? ""}
              >
                <Input
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("error.username.noSpace"),
                  },
                ]}
                name="username"
                label={t("label.username")}
                initialValue={data?.data.userName ?? ""}
              >
                <Input
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  disabled={!editing}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("error.email.required"),
                  },
                  {
                    type: "email",
                    message: t("error.email.invalid"),
                  },
                ]}
                name="email"
                label={t("label.email")}
                initialValue={data?.data.email ?? ""}
              >
                <Input
                  style={{
                    width: "300px",
                    height: "50px",
                  }}
                  disabled={!editing}
                />
              </Form.Item>
            </>
          ) : null}
        </Form>
        <div className="vert">
          <button
            onClick={() => handleEdit()}
            style={{
              backgroundColor: editing ? "#67be23" : "#2a132e",
            }}
          >
            {editing ? t("buttons.save") : t("buttons.edit")}
          </button>
        </div>
      </div>
      <Modal
        title={t("profile:successModal.title")}
        visible={showSuccess}
        cancelButtonProps={{
          hidden: true,
        }}
        footer={[
          <Button onClick={() => setShowSuccess(false)}>
            {t("buttons.ok")}
          </Button>,
        ]}
      >
        <p>{t("profile:successModal.message")}</p>
      </Modal>
      <Modal
        visible={showWarningModal}
        onOk={async () => {
          setShowWarningModal(false);
          if (await formRef.current?.validateFields()) {
            const res = await api.users.patchUser({
              userName: formRef.current?.getFieldValue("username"),
              email: formRef.current?.getFieldValue("email"),
            });
            if (res.kind === "ok") {
              await refetch();
              setShowSuccess(true);
              setEditing(false);
            } else {
              message.error(res.kind);
            }
          }
        }}
        onCancel={() => {
          setShowWarningModal(false);
        }}
        title={t("profile:warningModal.title")}
      >
        <p>{t("profile:warningModal.message")}</p>
      </Modal>
    </Show>
  );
};

import { Form } from "@pankod/refine-antd";
import { ComponentFormImageField } from "./ComponentFormImageField";
import { ComponentFormMultiLineTextField } from "./ComponentFormMultiLineTextField";
import { ComponentFormMultiSelectField } from "./ComponentFormMultiSelectField";
import { ComponentFormTextEditorField } from "./ComponentFormTextEditorField";
import { ComponentFormTextField } from "./ComponentFormTextField";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentFormValueField({
  field,
  data,
  fieldChanged,
  location,
}: {
  field: IComponentField;
  data: any;
  fieldChanged: FieldChangedCallback;
  location: string[];
}) {
  return (
    <Form.Item label={field.label ?? field.name} name={field.name}>
      {renderField(field, data, fieldChanged, location)}
    </Form.Item>
  );
}

function renderField(
  field: IComponentField,
  data: any,
  fieldChanged: FieldChangedCallback,
  location: string[]
) {
  switch (field.type) {
    case "text":
      return (
        <ComponentFormTextField
          field={field}
          data={data}
          fieldChanged={fieldChanged}
          location={location}
        />
      );
    case "multi-line-text":
      return (
        <ComponentFormMultiLineTextField
          field={field}
          data={data}
          fieldChanged={fieldChanged}
          location={location}
        />
      );
    case "multi-select":
      return (
        <ComponentFormMultiSelectField
          field={field}
          data={data}
          fieldChanged={fieldChanged}
          location={location}
        />
      );
      case "text-editor":
        return (
          <ComponentFormTextEditorField
            field={field}
            data={data}
            fieldChanged={fieldChanged}
            location={location}
          />
        );
        case "image":
          return (
            <ComponentFormImageField
              field={field}
              data={data}
              fieldChanged={fieldChanged}
              location={location}
            />
          );
  }
}

import { Api } from "../api";

export class CodeSnippetsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getCodeSnippet(id: string) {
    try {
      const response = await this.api.instance.get(`/code-snippets/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async postCodeSnippet(payload: any) {
    try {
      const response = await this.api.instance.post(`/code-snippets`, payload, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      });
      if (!(response.status === 201)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async putCodeSnippet(id: string, payload: any) {
    try {
      const response = await this.api.instance.put(
        `/code-snippets/${id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async deleteCodeSnippet(id: string) {
    try {
      const response = await this.api.instance.delete(`/code-snippets/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

import {
  Button,
  Edit,
  Form,
  FormInstance,
  Icons,
  InputNumber,
  message,
  Modal,
  Select,
} from "@pankod/refine-antd";
import api from "api/api";
import {
  CustomQuill,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
import { ComponentForm } from "components/ComponentForm/ComponentForm";
import {
  FieldChangedCallback,
  IComponentField,
} from "components/ComponentForm/interfaces";
import { IComponentDropDown, IContainerResponse } from "interfaces";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";

import "../style.scss";
import { ContainerContext, IContainerContext } from "./ContainerContext";
import _ from "lodash";
import { useTranslate } from "@pankod/refine-core";

const { SaveTwoTone } = Icons;

export const ContainerEdit = () => {
  const t = useTranslate();
  const QuillRef = useRef<HTMLDivElement>(null);
  const [quillValue, setQuillValue] = useState("");
  const [componentsDropDown, setComponentsDropDown] = useState<
    IComponentDropDown[]
  >([]);
  const [selectedComponentField, setSelectedComponentField] =
    useState<number>();
  const [componentFields, setComponentsFields] = useState<IComponentField[]>();
  const [type, setType] = useState("0");
  const formRef = createRef<FormInstance>();
  const [initialValues, setInitialValues] = useState<IContainerResponse | null>(
    null
  );
  const [showBackWarningModal, setShowBackWarningModal] = useState(false);

  const { handlePageChange, page, recordToShow, recordToUpdate } = useContext(
    ContainerContext
  ) as IContainerContext;

  async function showResponse() {
    var responseBody = {};
    responseBody["name"] = formRef.current?.getFieldValue("name");
    responseBody["description"] = formRef.current?.getFieldValue("description");
    responseBody["isActive"] =
      formRef.current?.getFieldValue("isActive") ?? false;
    responseBody["displayOrder"] = formRef.current?.getFieldValue("order");

    if (type === "0") {
      responseBody["type"] = "text-editor";
      responseBody["content"] = quillValue;
      responseBody["col"] = formRef.current?.getFieldValue("col");
    } else {
      responseBody["type"] = "component";
      responseBody["content"] = null;

      responseBody["componentData"] = initialValues?.componentData;
      responseBody["componentId"] = selectedComponentField;
    }
    const res = await api.pages.updatePageContainer(
      recordToShow?.id,
      responseBody,
      recordToUpdate
    );
    if (res.kind === "ok") {
      message.success(t("success.container.edit"));
      handlePageChange(1);
    }
    if (res.kind !== "ok") message.error(res.kind);
  }

  useEffect(() => {
    if (page !== 0) {
      setQuillValue("");
      // setType("");
      formRef.current?.resetFields();
      setSelectedComponentField(undefined);
      setInitialValues(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    async function getFields() {
      const res = await api.pages.getComponentFields(selectedComponentField);
      if (res.kind === "ok") {
        setComponentsFields(res.data);
      }
    }

    if (selectedComponentField !== undefined) getFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponentField]);

  useEffect(() => {
    async function getInitialFields() {
      const pres = await api.pages.getComponents();
      if (pres.kind === "ok") setComponentsDropDown(pres.data);
      const res = await api.pages.getInitialValuesContainer(
        recordToShow?.id,
        recordToUpdate
      );
      if (res.kind === "ok") {
        setType(() => (res.data.type === "component" ? "1" : "0"));
        if (res.data.componentId)
          setSelectedComponentField(res.data?.componentId);
        setInitialValues(res.data);
        setQuillValue(res.data?.content);
      }
    }

    if (recordToUpdate) getInitialFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordToUpdate]);

  const fieldChanged: FieldChangedCallback = (
    location: string[],
    value: any
  ) => {
    const componentData = initialValues?.componentData;
    const currentValues = { ...componentData };

    _.set(currentValues, location, value);

    setInitialValues({ ...initialValues!, componentData: currentValues });
  };

  const textEditorType = (
    <>
      <Form.Item label={t("label.richText")}>
        <div
          style={{
            outline: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <div ref={QuillRef}>
            <CustomQuill setValue={setQuillValue} value={quillValue} />
          </div>
        </div>
      </Form.Item>
      <Form.Item
        tooltip={t("tooltip.col")}
        label={t("label.col")}
        name="col"
        initialValue={initialValues ? initialValues.col : ""}
      >
        <Select
          options={[
            {
              value: "10",
              label: "%10 10/1",
            },
            {
              value: "11.11111111111111",
              label: "%11.1 9/1",
            },
            {
              value: "12.5",
              label: "%12.5 8/1",
            },
            {
              value: "20",
              label: "%20 5/1",
            },
            {
              value: "25",
              label: "%25 4/1%",
            },
            {
              value: "33.333333",
              label: "%33.3 3/1",
            },
            {
              value: "50",
              label: "%50 2/1",
            },
            {
              value: "66.666666",
              label: "%66.6 3/2",
            },
            {
              value: "75",
              label: "%75 4/3",
            },
            {
              value: "100",
              label: "%100 1/1",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label={t("label.order")}
        tooltip={t("tooltip.order")}
        name="order"
        initialValue={initialValues ? initialValues.order : ""}
      >
        <InputNumber min={-1000000} max={1000000} />
      </Form.Item>
    </>
  );
  return (
    <>
      <style>
        {`
          .ant-space-item{
            width : 100%;
        
          }`}
      </style>
      {initialValues && (type === "0" || (type === "1" && componentFields)) && (
        <Edit
          title={t("pages:container.edit.title")}
          pageHeaderProps={{
            onBack: () => {
              setShowBackWarningModal(true);
            },
            extra: null,
          }}
          canDelete={false}
          actionButtons={
            <Button
              icon={<SaveTwoTone color="white" twoToneColor="white" />}
              onClick={() => {
                showResponse();
              }}
              style={{
                color: "white",
                background: "#2a132e",
              }}
            >
              {t("buttons.save")}
            </Button>
          }
        >
          <Form layout="vertical" ref={formRef}>
            <InputComponent
              index="text"
              label={t("label.name")}
              name="name"
              initialValue={initialValues["name"] ?? ""}
            />
            <InputComponent
              index="multi-line-text"
              label={t("label.description")}
              name="description"
              initialValue={initialValues["description"] ?? ""}
            />
            <InputComponent
              index="bool"
              label={t("label.isActive")}
              name="isActive"
              initialValue={initialValues["isActive"] ?? false}
            />
            <Form.Item label={t("label.type")} name="type" initialValue={type}>
              <Select
                placeholder={t("pages:container.select.placeholder")}
                options={[
                  {
                    label: t("pages:container.select.textEditor"),
                    value: "0",
                  },
                  {
                    label: t("pages:container.select.component"),
                    value: "1",
                  },
                ]}
                value={type}
                onChange={(e) => setType(e)}
              />
            </Form.Item>
            {type === "0" && textEditorType}
            {type === "1" && (
              <Form.Item
                label={t("label.componentType")}
                name="componenttype"
                initialValue={selectedComponentField}
              >
                <Select
                  placeholder={t("pages:container.select.placeholder")}
                  value={selectedComponentField}
                  options={componentsDropDown.map((item) => {
                    return {
                      value: item.id,
                      label: item.name,
                    };
                  })}
                  onChange={(e) => {
                    setSelectedComponentField(e);
                  }}
                />
              </Form.Item>
            )}
            {type === "1" && componentFields && (
              <>
                <ComponentForm
                  data={initialValues.componentData}
                  fields={componentFields}
                  fieldChanged={fieldChanged}
                />
              </>
            )}
          </Form>
          <Modal
            closable
            title={t("pages:container.edit.warningModal.title")}
            onCancel={() => setShowBackWarningModal(false)}
            footer={[
              <DefaultModalCancelButton
                onClick={() => setShowBackWarningModal(false)}
              />,
              <DefaultModalOkayButton
                onClick={() => {
                  setShowBackWarningModal(false);
                  handlePageChange(1);
                }}
              />,
            ]}
            visible={showBackWarningModal}
          >
            {t("pages:container.edit.warningModal.message")}
          </Modal>
        </Edit>
      )}
    </>
  );
};

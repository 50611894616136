import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { TableProvider } from "./AuthContext";
import { useTranslate } from "@pankod/refine-core";

function CustomerPanel() {
  const t = useTranslate();
  return (
    <React.Suspense fallback={t("loading.loading")}>
      <React.StrictMode>
        <TableProvider>
          <App />
        </TableProvider>
      </React.StrictMode>
    </React.Suspense>
  );
}

ReactDOM.render(<CustomerPanel />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  Button,
  CloneButton,
  EditButton,
  Icons,
  Input,
  List,
  message,
  Modal,
  Space,
  Table,
  TableColumnProps,
  Tooltip,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import api from "api/api";
import { AuthContext, IAuthContext } from "AuthContext";
import {
  defaultButtonFilled,
  defaultButtonFilled18,
  defaultHeader,
} from "components";
import Highlighter from "react-highlight-words";
import {
  ILoopablePageColumns,
  IPageListResponse,
  IPageResponse,
} from "interfaces";
import { useContext, useRef, useState } from "react";
import { LOOPABLE_PAGES_COLUMNS } from "styleObjects";
import { ContainerModal } from "./container";
import { ContainerProvider } from "./container/ContainerContext";
const { DeleteFilled, LaptopOutlined, EyeFilled, SearchOutlined } = Icons;
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

type DataIndex = keyof DataType;

export const PageList = () => {
  const t = useTranslate();
  const [showContainerModal, setShowContainerModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCloneConfirmation, setShowCloneConfirmation] = useState(false);
  const idToDelete = useRef<string | null>(null);
  const idToClone = useRef<string | null>(null);
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const [showPreview, setShowPreview] = useState(false);
  const [previewLink, setPreviewLink] = useState("");
  const [containerRecord, setcontainerRecord] = useState<IPageResponse | null>(
    null
  );
  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);
  //
  const { data, isFetching, refetch } = useCustom<IPageListResponse[]>({
    url: "/pages",
    method: "get",
    config: {
      filters: [
        {
          field: "languageId",
          value: languageId,
          operator: "eq",
        },
      ],
    },
  });

  function handleContainerModal(action: boolean, record: IPageResponse | null) {
    setShowContainerModal(action);
    setcontainerRecord(record);
  }

  async function handleDelete() {
    setShowDeleteConfirmation(false);
    if (idToDelete.current === null) return;
    const res = await api.pages.deletePage(idToDelete.current);
    if (res.kind === "ok") {
      refetch();
      idToDelete.current = null;
      message.success(t("success.page.delete"));
    } else {
      message.error(res.kind);
    }
  }

  async function handleClone() {
    setShowCloneConfirmation(false);
    if (idToClone.current === null) return;
    const res = await api.pages.clonePage(idToClone.current);
    if (res.kind === "ok") {
      refetch();
      idToClone.current = null;
      message.success(t("success.page.clone"));
    } else {
      message.error(res.message ?? t("error.unexpected"));
    }
  }

  async function handlePreview(id: any) {
    setShowPreview(true);
    const res = await api.pages.getPreviewURL(id);
    if (res.kind === "ok") setPreviewLink(res.data.url);
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortingData = (
    item: ILoopablePageColumns
  ): TableColumnProps<IPageResponse> => {
    let object: TableColumnProps<IPageResponse> = {};
    switch (item.label) {
      case t("table.id"):
        break;
      case t("table.name"):
      case t("table.link"):
        object = getColumnSearchProps(item.name);
        break;
      case t("table.isActive"):
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = a[item.name] ?? "z";
            let second = b[item.name] ?? "z";
            return String(first).localeCompare(String(second));
          },
        };
        break;
      default:
        break;
    }

    return object;
  };

  const columnTitleComponent = LOOPABLE_PAGES_COLUMNS(t).map((item) => (
    <Table.Column
      title={item.label}
      key={item.name}
      {...sortingData(item)}
      dataIndex={item.name}
      render={(render) => {
        if (!render) return "null";
        return String(render);
      }}
    />
  ));
  return (
    <List
      pageHeaderProps={{
        title: defaultHeader(t("pages:list.title")),
      }}
      createButtonProps={{
        style: defaultButtonFilled18,
        children: t("buttons.create"),
      }}
    >
      <Table dataSource={data?.data} loading={isFetching}>
        {columnTitleComponent}
        <Table.Column<IPageResponse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Tooltip placement="top" title={t("tooltip.containers")}>
                  <Button
                    size="large"
                    icon={<LaptopOutlined />}
                    style={{
                      color: "#0e6d33",
                    }}
                    onClick={() => {
                      handleContainerModal(true, record);
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.edit")}>
                  <EditButton
                    size="large"
                    recordItemId={record.id!}
                    hideText
                    style={{
                      color: "#0e6d33",
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.delete")}>
                  <Button
                    icon={
                      <DeleteFilled
                        twoToneColor="#eb2f96"
                        color="red"
                        style={{
                          color: "#ff4343",
                        }}
                        onClick={() => {
                          idToDelete.current = record.id;
                          setShowDeleteConfirmation(true);
                        }}
                      />
                    }
                    size="large"
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.clone")}>
                  <CloneButton
                    size="large"
                    style={{
                      color: "blue",
                    }}
                    hideText
                    onClick={() => {
                      idToClone.current = record.id;
                      setShowCloneConfirmation(true);
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.preview")}>
                  <Button
                    size="large"
                    style={{
                      color: "blue",
                    }}
                    icon={<EyeFilled />}
                    onClick={() => {
                      handlePreview(record.id);
                    }}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        visible={showContainerModal}
        title={defaultHeader(
          (containerRecord ? containerRecord.name : "") +
            t("pages:list.containerModal.title")
        )}
        onCancel={() => handleContainerModal(false, null)}
        footer={[
          <Button
            style={defaultButtonFilled}
            onClick={() => handleContainerModal(false, null)}
          >
            {t("buttons.close")}
          </Button>,
        ]}
        width="90vw"
      >
        <ContainerProvider
          key={containerRecord ? containerRecord.id : "dummykey"}
        >
          <ContainerModal record={containerRecord!} />
        </ContainerProvider>
      </Modal>
      <Modal
        visible={showDeleteConfirmation}
        title={t("pages:list.deleteModal.title")}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        onOk={handleDelete}
      >
        <p>{t("pages:list.deleteModal.message")}</p>
      </Modal>
      <Modal
        visible={showCloneConfirmation}
        title={t("pages:list.cloneModal.title")}
        onCancel={() => {
          setShowCloneConfirmation(false);
        }}
        onOk={handleClone}
      >
        <p>{t("pages:list.cloneModal.message")}</p>
      </Modal>
      <Modal
        visible={showPreview}
        title={t("pages:list.previewModal.title")}
        onCancel={() => {
          setShowPreview(false);
          setPreviewLink("");
        }}
        onOk={() => {
          setShowPreview(false);
          setPreviewLink("");
        }}
        style={{
          top: "2.5vh",
        }}
        bodyStyle={{
          height: "80vh",
        }}
        width="90vw"
      >
        <iframe
          src={previewLink}
          title={t("pages:list.previewModal.title")}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </Modal>
    </List>
  );
};

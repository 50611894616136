import {
  Button,
  Edit,
  Form,
  FormInstance,
  Icons,
  message,
  Modal,
} from "@pankod/refine-antd";
import api from "api/api";
import {
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
import { createRef, useContext, useEffect, useState } from "react";
import "../style.scss";
import { ContainerContext, IContainerContext } from "./ContainerContext";
import { useTranslate } from "@pankod/refine-core";
const { SaveTwoTone } = Icons;
export const ContainerMutate = () => {
  const t = useTranslate();
  const formRef = createRef<FormInstance>();
  const [showBackWarningModal, setShowBackWarningModal] = useState(false);

  const { handlePageChange, page, recordToShow } = useContext(
    ContainerContext
  ) as IContainerContext;

  async function showResponse() {
    var responseBody = {};
    responseBody["name"] = formRef.current?.getFieldValue("name");
    responseBody["description"] = formRef.current?.getFieldValue("description");
    responseBody["type"] = "text-editor";
    responseBody["isActive"] = true;
    responseBody["displayOrder"] = formRef.current?.getFieldValue("order");
    responseBody["content"] = formRef.current?.getFieldValue("content");
    responseBody["col"] = formRef.current?.getFieldValue("col");
    const res = await api.pages.createPageContainer(
      responseBody,
      parseInt(recordToShow?.id ?? "0")
    );
    if (res.kind === "ok") {
      message.success(t("success.component.create"));
      handlePageChange(1);
    }
    if (res.kind !== "ok") message.error(res.kind);
  }

  useEffect(() => {
    if (page !== 2) {
      formRef.current?.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const textEditorType = (
    <>
      <InputComponent
        index="text-editor"
        name="content"
        label={t("label.richText")}
      />
      <InputComponent
        index="relation"
        name="col"
        label={t("label.col")}
        tooltip={t("tooltip.col")}
        selectOptions={[
          {
            value: "10",
            label: "%10 10/1",
          },
          {
            value: "11.11111111111111",
            label: "%11.1 9/1",
          },
          {
            value: "12.5",
            label: "%12.5 8/1",
          },
          {
            value: "20",
            label: "%20 5/1",
          },
          {
            value: "25",
            label: "%25 4/1%",
          },
          {
            value: "33.333333",
            label: "%33.3 3/1",
          },
          {
            value: "50",
            label: "%50 2/1",
          },
          {
            value: "66.666666",
            label: "%66.6 3/2",
          },
          {
            value: "75",
            label: "%75 4/3",
          },
          {
            value: "100",
            label: "%100 1/1",
          },
        ]}
      />
      <InputComponent
        index="number"
        name="order"
        label={t("label.order")}
        tooltip={t("tooltip.order")}
      />
    </>
  );
  return (
    <Edit
      title={t("pages:component.create.title")}
      pageHeaderProps={{
        onBack: () => {
          setShowBackWarningModal(true);
        },
        extra: null,
      }}
      canDelete={false}
      actionButtons={
        <Button
          icon={<SaveTwoTone color="white" twoToneColor="white" />}
          onClick={() => {
            showResponse();
          }}
          style={{
            color: "white",
            background: "#2a132e",
          }}
        >
          {t("buttons.save")}
        </Button>
      }
    >
      <Form layout="vertical" ref={formRef}>
        <InputComponent index="text" name="name" label={t("label.name")} />
        <InputComponent
          index="multi-line-text"
          name="description"
          label={t("label.description")}
        />
        {textEditorType}
      </Form>
      <Modal
        closable
        title={t("pages:component.create.warningModal.title")}
        onCancel={() => setShowBackWarningModal(false)}
        footer={[
          <DefaultModalCancelButton
            onClick={() => setShowBackWarningModal(false)}
          />,
          <DefaultModalOkayButton
            onClick={() => {
              setShowBackWarningModal(false);
              handlePageChange(1);
            }}
          />,
        ]}
        visible={showBackWarningModal}
      >
        {t("pages:component.create.warningModal.message")}
      </Modal>
    </Edit>
  );
};

import {
  List,
  Table,
  Space,
  EditButton,
  CloneButton,
  Button,
  Icons,
  Modal,
  Tooltip,
  TableColumnProps,
  Input,
} from "@pankod/refine-antd";
import { useCustom, useResource, useTranslate } from "@pankod/refine-core";
import { ICreateModule, IRecord, ITableRecord } from "interfaces";
import * as XLSX from "xlsx";
// import type { InputRef } from "@pankod/refine-antd";
import Highlighter from "react-highlight-words";
import "./style.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IAuthContext, AuthContext } from "../../AuthContext";
import api from "api/api";
import { defaultButtonFilled18, defaultHeader } from "components";
import { TableColumn } from "./components/table_column";
import moment from "moment";
const { DeleteFilled, SearchOutlined } = Icons;

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
}

type DataIndex = keyof DataType;

export const ModuleList = () => {
  const t = useTranslate();
  const idToDelete = useRef("-1");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { resourceName } = useResource();
  const [tableData, setTableData] = useState<IRecord[]>();
  const [tableColumns, settableColumns] = useState<ICreateModule[]>();
  const { resource } = useResource();
  const { languageId } = useContext(AuthContext) as IAuthContext;
  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);
  //
  const { data, isFetching, isLoading, refetch } = useCustom<ITableRecord>({
    url: `/tables/${resourceName}/records`,
    method: "get",
    config: {
      filters: [
        {
          field: "languageId",
          value: languageId,
          operator: "eq",
        },
      ],
    },
  });

  async function deleteModalUsingID() {
    await api.tables.deleteRecord(resourceName, idToDelete.current);
    setShowDeleteModal(false);
    refetch();
  }
  useEffect(() => {
    async function getModuleFields() {
      const response = await api.tables.getFields(resourceName, languageId);
      if (response.kind !== "ok") return;
      settableColumns(response.data);
      setTableData(data?.data.records ?? []);
    }
    if (data) {
      getModuleFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function exportXLS() {
    if (!tableData) return;
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `${resource.label ?? "DataExport"} ${moment().format("YYYY-MM-DD")}.xlsx`
    );
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortingData = (
    item: ICreateModule
  ): TableColumnProps<ICreateModule> => {
    let object: TableColumnProps<ICreateModule> = {};
    switch (item.type) {
      case "text":
        object = getColumnSearchProps(item.systemName);
        break;
      case "bool":
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = a[item.systemName] ?? "z";
            let second = b[item.systemName] ?? "z";
            return String(first).localeCompare(String(second));
          },
        };
        break;
      case "date":
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = new Date(a[item.systemName] ?? 0);
            let second = new Date(b[item.systemName] ?? 0);

            return first.getTime() - second.getTime();
          },
        };
        break;
      case "date-time":
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = new Date(a[item.systemName] ?? -1);
            let second = new Date(b[item.systemName] ?? -1);

            return first.getTime() - second.getTime();
          },
        };
        break;
      case "number":
        object = {
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => {
            let first = a[item.systemName] ?? 0;
            let second = b[item.systemName] ?? 0;

            return first - second;
          },
        };
        break;
      case "multi-line-text":
        object = getColumnSearchProps(item.systemName);
        break;
      default:
        break;
    }
    if (item.systemName === "Id")
      object = {
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a?.Id - b?.Id,
      };
    return object;
  };

  return (
    <List
      pageHeaderProps={{
        title: defaultHeader(resource.label ?? "NaN"),
        footer: (
          <Space style={{ marginBottom: "20px" }}>
            <Button style={defaultButtonFilled18} onClick={exportXLS}>
              {t("buttons.export")}
            </Button>
          </Space>
        ),
      }}
      createButtonProps={{
        style: defaultButtonFilled18,
        children: t("buttons.create"),
      }}
      canCreate
    >
      <Table
        loading={isFetching || isLoading}
        dataSource={tableData}
        showSorterTooltip={false}
      >
        {tableColumns &&
          data &&
          tableColumns.map((item, index) => {
            if (item.systemName === "_id" || !item.showList) return null;
            return (
              <Table.Column
                dataIndex={item.systemName}
                title={item.name}
                key={item.systemName + index}
                {...sortingData(item)}
                render={(text, record: any, index) => (
                  <TableColumn
                    item={item}
                    record={record}
                    text={text}
                    key={"tc" + index}
                  />
                )}
              />
            );
          })}

        <Table.Column<IRecord>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Tooltip placement="top" title={t("buttons.edit")}>
                  <EditButton
                    size="large"
                    recordItemId={record._id}
                    hideText
                    style={{
                      color: "#0e6d33",
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.delete")}>
                  <Button
                    icon={
                      <DeleteFilled
                        twoToneColor="#eb2f96"
                        color="red"
                        style={{
                          color: "#ff4343",
                        }}
                      />
                    }
                    size="large"
                    onClick={() => {
                      setShowDeleteModal(true);
                      idToDelete.current = record._id!;
                    }}
                  />
                </Tooltip>
                <Tooltip placement="left" title={t("buttons.clone")}>
                  <CloneButton
                    size="large"
                    recordItemId={record._id!}
                    hideText
                    style={{
                      color: "blue",
                    }}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        title={t("meta:list.deleteModal.title")}
        visible={showDeleteModal}
        onOk={async () => {
          if (idToDelete.current === "-1") return;
          deleteModalUsingID();
        }}
        onCancel={() => setShowDeleteModal(false)}
        closable
        okText={t("buttons.ok")}
        cancelText={t("buttons.cancel")}
      >
        <p>{t("meta:list.deleteModal.message")}</p>
      </Modal>
    </List>
  );
};


import env from "../config/env"

const API_URL = env.API_URL


export interface ApiConfig {
    url: string
    timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
    url: API_URL ?? "",
    timeout: 10000,
}
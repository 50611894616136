import {
  Button,
  Collapse,
  Create,
  Form,
  FormInstance,
  Icons,
  Modal,
} from "@pankod/refine-antd";
import { useNavigation, useResource, useTranslate } from "@pankod/refine-core";
import api from "api/api";
import { AuthContext, IAuthContext } from "AuthContext";
import {
  defaultButtonFilled18,
  defaultHeader,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
import { IarrtObject, IAttribute, IMetaPayload, IPageId } from "interfaces";
import { createRef, useContext, useEffect, useState } from "react";
import { buttonw100, LOOPABLE_ITEMS_META } from "styleObjects";
import { ArrtibuteForm } from "./components/customAttr";
const { SaveTwoTone, PlusCircleFilled } = Icons;
const { Panel } = Collapse;
export const MetaCreate = () => {
  const t = useTranslate();
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const formRef = createRef<FormInstance>();
  const [attrCount, setAttrCount] = useState<IarrtObject[]>([]);
  // to prevent react keeping state of _ forms we use keyscount this way new forms will not have _ one values
  // we are setting key to the item itemKey attr
  const [keysCount, setKeysCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { id } = useResource();
  const nav = useNavigation();
  const [initialValues, setInitialValues] = useState<IMetaPayload | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [pagesID, setPagesID] = useState<IPageId[]>();
  function handleChange(_: IarrtObject[]) {
    setAttrCount(_);
  }

  useEffect(() => {
    async function getInitialValues() {
      setLoading((_) => !_);
      const response = await api.pages.getAllPagesForDropDown(
        (languageId ?? 1)?.toString()
      );
      if (response.kind !== "ok") return;
      const { data } = response;
      setPagesID([{ id: null, name: t("label.allPages") }, ...data]);
      if (id) {
        const response = await api.metaTags.getMetaTag(id as string);
        if (response.kind !== "ok") return;
        const { data } = response;

        setInitialValues(data);
        if (data.attributes) {
          var tempAttrList = data.attributes as IAttribute[];
          setKeysCount(tempAttrList!.length);
          tempAttrList!.map((_, index) => {
            attrCount.push({ itemKey: index });
            return _;
          });
        }
      }

      setLoading((_) => !_);
    }

    getInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addAttr() {
    setAttrCount((_) => [..._, { itemKey: keysCount + 1 }]);
    setKeysCount((_) => _ + 1);
  }

  async function createNewMeta() {
    let payLoad: IMetaPayload = {};
    let attrList: IAttribute[] = [];
    LOOPABLE_ITEMS_META(t).map((item) => {
      if (formRef.current?.getFieldValue(item.name))
        payLoad[item.name] = formRef.current?.getFieldValue(item.name);
      return item;
    });
    attrCount.map((key) => {
      if (
        formRef.current?.getFieldValue(`arrtName${key.itemKey}`) &&
        formRef.current?.getFieldValue(`arrtValue${key.itemKey}`)
      )
        attrList.push({
          name: formRef.current?.getFieldValue(`arrtName${key.itemKey}`),
          value: formRef.current?.getFieldValue(`arrtValue${key.itemKey}`),
        });
      return key;
    });
    if (attrList.length) payLoad.attributes = attrList;
    setLoading((_) => !_);
    const response = await api.metaTags.postMetaTag(payLoad);
    if (response.kind === "ok") setShowModal(true);
    setLoading((_) => !_);
  }

  return (
    <Create
      actionButtons={[
        <Button
          icon={<SaveTwoTone color="white" twoToneColor="white" />}
          style={defaultButtonFilled18}
          onClick={createNewMeta}
        >
          {t("buttons.save")}
        </Button>,
      ]}
      isLoading={loading}
      pageHeaderProps={{
        extra: null,
        title: defaultHeader(t("meta:create.title")),
      }}
    >
      {(!id || !loading) && (
        <Form layout="vertical" ref={formRef}>
          {LOOPABLE_ITEMS_META(t).map((name) =>
            name.name === "pageId" ? (
              <InputComponent
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={
                  initialValues
                    ? pagesID?.find((element) => {
                        if (element.id === initialValues[name.name]) {
                          return element;
                        }
                        return false;
                      })?.name
                    : t("label.allPages")
                }
                selectOptions={pagesID?.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                index="relation"
              />
            ) : name.name === "description" ? (
              <InputComponent
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : ""}
                index="multi-line-text"
              />
            ) : (
              <InputComponent
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : ""}
                index="text"
              />
            )
          )}
          <div style={{ margin: "20px 0" }}>
            <Collapse defaultActiveKey={["initialpanel"]}>
              <Panel header="Attributes" key="initialpanel">
                {attrCount.map((_) => (
                  <ArrtibuteForm
                    initialValues={initialValues ?? null}
                    item={_}
                    callBack={handleChange}
                    attrCount={attrCount}
                  />
                ))}
                <Button
                  icon={<PlusCircleFilled />}
                  style={buttonw100}
                  onClick={addAttr}
                >
                  {t("buttons.addAttr")}
                </Button>
              </Panel>
            </Collapse>
          </div>
        </Form>
      )}
      <Modal
        closable
        title={t("meta:warningModal.title")}
        visible={showModal}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
      >
        <p>{t("meta:create.warningModal.message")}</p>
      </Modal>
    </Create>
  );
};

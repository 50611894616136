import { useTranslate } from "@pankod/refine-core";
import {
  ILoopableNamesCode,
  ILoopableNamesMeta,
  ILoopablePageColumns,
} from "interfaces";
import { CSSProperties } from "react";

export const buttonStyle: CSSProperties = {
  color: "white",
  background: "#2a132e",
};
export const buttonw100: CSSProperties = {
  width: "100%",
  height: "50px",
  backgroundColor: "#2a132e",
  color: "white",
};
export const buttonw1002: CSSProperties = {
  width: "100%",
  height: "40px",
  border: "1px solid #2a132e",
  color: "#2a132e",
  marginBottom: "20px",
};

export const horizontalStyle: CSSProperties = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "20px",
};

export const TEXT_CUTOFF_POINT = 100;

export const LOOPABLE_ITEMS_META: (
  t: ReturnType<typeof useTranslate>
) => ILoopableNamesMeta[] = (t) => [
  {
    name: "name",
    label: t("loopable:meta.name.label"),
    tooltip: t("loopable:meta.name.tooltip"),
  },
  {
    label: t("loopable:meta.description.label"),
    tooltip: t("loopable:meta.description.tooltip"),
    name: "description",
  },
  {
    label: t("loopable:meta.charset.label"),
    tooltip: t("loopable:meta.charset.tooltip"),
    name: "charset",
  },
  {
    label: t("loopable:meta.content.label"),
    tooltip: t("loopable:meta.content.tooltip"),
    name: "content",
  },
  {
    label: t("loopable:meta.property.label"),
    tooltip: t("loopable:meta.property.tooltip"),
    name: "property",
  },
  {
    label: t("loopable:meta.pageId.label"),
    tooltip: t("loopable:meta.pageId.tooltip"),
    name: "pageId",
  },
];

export const LOOPABLE_ITEMS_CODE: (
  t: ReturnType<typeof useTranslate>
) => ILoopableNamesCode[] = (t) => [
  {
    name: "name",
    label: t("loopable:code.name.label"),
    tooltip: t("loopable:code.name.tooltip"),
  },
  {
    label: t("loopable:code.description.label"),
    tooltip: t("loopable:code.description.tooltip"),
    name: "description",
  },
  {
    label: t("loopable:code.code.label"),
    tooltip: t("loopable:code.code.tooltip"),
    name: "code",
  },
  {
    label: t("loopable:code.headOrBody.label"),
    tooltip: t("loopable:code.headOrBody.tooltip"),
    name: "headOrBody",
  },
  {
    label: t("loopable:code.pages.label"),
    tooltip: t("loopable:code.pages.tooltip"),
    name: "pages",
  },
];

export const LOOPABLE_PAGES_COLUMNS: (
  t: ReturnType<typeof useTranslate>
) => ILoopablePageColumns[] = (t) => [
  {
    name: "id",
    label: t("loopable:pages.id.label"),
    description: t("loopable:pages.id.description"),
  },
  {
    name: "name",
    label: t("loopable:pages.name.label"),
    description: t("loopable:pages.name.description"),
  },
  {
    name: "link",
    label: t("loopable:pages.link.label"),
    description: t("loopable:pages.link.description"),
  },
  {
    name: "isActive",
    label: t("loopable:pages.isActive.label"),
    description: t("loopable:pages.isActive.description"),
  },
];

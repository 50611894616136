import { Api } from "../api";

export class FileApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async uploadFile(form: any) {
    try {
      const response = await this.api.instance.post("/files", form);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

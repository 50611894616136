import { Api } from "../api";

export class TablesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getTables() {
    try {
      const response = await this.api.instance.get("/Tables");
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getFields(tableId: string, languageId: number | undefined) {
    try {
      const response = await this.api.instance.get(
        `/Tables/${tableId}/fields`,
        {
          params: {
            languageId,
          },
        }
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async postRecords(tableId: string, payload: any) {
    try {
      const response = await this.api.instance.post(
        `/Tables/${tableId}/records`,
        payload,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 201)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getRecord(tableId: string, recordId: string) {
    try {
      const response = await this.api.instance.get(
        `/Tables/${tableId}/records/${recordId}`
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async patchRecord(tableId: string, recordId: string, payload: any) {
    try {
      const response = await this.api.instance.patch(
        `/Tables/${tableId}/records/${recordId}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async deleteRecord(tableId: string, recordId: string) {
    try {
      const response = await this.api.instance.delete(
        `/Tables/${tableId}/records/${recordId}`
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

import {
  Edit,
  Form,
  Modal,
  Button,
  Icons,
  FormInstance,
} from "@pankod/refine-antd";
import { useEffect, useState, createRef, useContext } from "react";
import {
  useCustom,
  useNavigation,
  useResource,
  useTranslate,
} from "@pankod/refine-core";
import dayjs, { Dayjs } from "dayjs";
import { ICreateModule } from "interfaces";
import { IAuthContext, AuthContext } from "../../AuthContext";
import api from "api/api";
import {
  defaultButtonFilled18,
  defaultHeader,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
const { SaveTwoTone } = Icons;

export const EditModule = () => {
  const t = useTranslate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSaveWarningModal, setshowSaveWarningModal] = useState(false);
  const nav = useNavigation();
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const { resourceName, id, resource } = useResource();
  const formRef = createRef<FormInstance>();
  const { data, isLoading } = useCustom<ICreateModule[]>({
    url: `${api.config.url}/tables/${resourceName}/fields`,
    method: "get",
    config: {
      filters: [
        {
          field: "languageId",
          value: languageId,
          operator: "eq",
        },
      ],
    },
  });

  async function saveEdit() {
    let payLoad: any = {};
    if (true) {
      data?.data?.map?.((e: ICreateModule) => {
        switch (e.type) {
          case "id":
            break;
          case "bool":
            const booleanValue = document.getElementById(
              e.systemName
            ) as HTMLInputElement;
            payLoad[e.systemName] =
              booleanValue.value === "true" ? true : false;
            break;
          case "date":
            const dateValue = formRef.current?.getFieldValue(
              e.systemName
            ) as Dayjs;
            if (dateValue)
              payLoad[e.systemName] = dateValue.format("YYYY-MM-DD");
            break;
          case "date-time":
            const dateTimeValue = formRef.current?.getFieldValue(
              e.systemName
            ) as Dayjs;
            if (dateTimeValue)
              payLoad[e.systemName] = dateTimeValue.format(
                "YYYY-MM-DDTHH:mm:ss"
              );
            break;
          case "relation":
            if (formRef.current?.getFieldValue(e.systemName)) {
              payLoad[e.systemName] = formRef.current.getFieldValue(
                e.systemName
              );
            }
            break;
          default:
            if (formRef.current?.getFieldValue(e.systemName))
              payLoad[e.systemName] = formRef.current?.getFieldValue(
                e.systemName
              );
            break;
        }
        return e;
      });
    }
    await api.tables.patchRecord(resourceName, id as string, payLoad);
    setshowSaveWarningModal(false);
    setShowSuccess(true);
  }

  useEffect(() => {
    const getData = async () => {
      let retries = 5;
      const interval = setInterval(async () => {
        if (!retries) clearInterval(interval);
        const response = await api.tables.getRecord(resourceName, id as string);
        if (response.kind !== "ok") {
          retries--;
          return;
        }
        clearInterval(interval);
        setInitialValues(response.data);
      }, 500);
    };
    if (id && data) getData();
  }, [data, id, resourceName]);

  return (
    <>
      <Edit
        pageHeaderProps={{
          onBack: (e) => {
            setIsModalVisible(true);
          },
          extra: null,
          title: defaultHeader(resource.label + ""),
        }}
        canDelete={false}
        isLoading={isLoading}
        actionButtons={
          <Button
            icon={<SaveTwoTone color="white" twoToneColor="white" />}
            onClick={() => {
              setshowSaveWarningModal(true);
            }}
            style={defaultButtonFilled18}
          >
            {t("buttons.save")}
          </Button>
        }
      >
        <Form layout="vertical" ref={formRef}>
          {initialValues &&
            data &&
            data?.data.map?.((e: ICreateModule) => {
              switch (e.type) {
                case "file":
                case "multi-line-text":
                case "bool":
                case "number":
                case "rich-text-editor":
                case "text":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues ? initialValues[e.systemName] : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                    />
                  );
                // if it is a relation
                case "relation":
                  return (
                    <InputComponent
                      index={e.type}
                      relationType={e.relationType}
                      initialValue={
                        initialValues ? initialValues[e.systemName] ?? "" : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      selectOptions={e.relationTableRecords?.map((e) => {
                        return {
                          label: e.name,
                          value: e.id,
                        };
                      })}
                    />
                  );
                case "date":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? initialValues[e.systemName]
                            ? dayjs(initialValues[e.systemName])
                            : null
                          : null
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        };
                      }}
                    />
                  );
                case "date-time":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? initialValues[e.systemName]
                            ? dayjs(initialValues[e.systemName])
                            : null
                          : null
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        };
                      }}
                    />
                  );
                case "json":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? JSON.stringify(initialValues[e.systemName])
                          : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                    />
                  );
                default:
                  return null;
              }
            })}
        </Form>
      </Edit>
      <Modal
        title={t("module:edit.modal.title")}
        visible={isModalVisible}
        closable
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <DefaultModalCancelButton onClick={() => setIsModalVisible(false)} />,
          <DefaultModalOkayButton onClick={() => nav.goBack()} />,
        ]}
      >
        <p>{t("module:edit.modal.message")}</p>
      </Modal>
      <Modal
        title={t("module:edit.saveWarningModal.title")}
        closable
        visible={showSaveWarningModal}
        onOk={async () => {
          await saveEdit();
        }}
        onCancel={() => setshowSaveWarningModal(false)}
        footer={[
          <DefaultModalCancelButton
            onClick={() => setshowSaveWarningModal(false)}
          />,
          <DefaultModalOkayButton onClick={() => saveEdit()} />,
        ]}
      >
        <p>{t("module:edit.saveWarningModal.message")}</p>
      </Modal>
      <Modal
        title={t("module:edit.successModal.title")}
        visible={showSuccess}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
      >
        <p>{t("module:edit.successModal.message")}</p>
      </Modal>
    </>
  );
};

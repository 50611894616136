import {
  DatePicker,
  Form,
  Icons,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
  Upload,
  UploadProps,
} from "@pankod/refine-antd";
import "./InputComponent.scss";
import { useContext, useMemo, useRef, useState } from "react";
import api from "api/api";
import ReactQuill from "react-quill";
import { AuthContext, IAuthContext } from "AuthContext";
import { loadAuthData } from "auth/auth";
import { defaultIconColor } from "components/commonStyles";
import { useTranslate } from "@pankod/refine-core";
// input component which will contain all non complex state managed input components
type InputProps = {
  name: any;
  index: string;
  label: string;
  initialValue?: any;
  description?: string;
  relationType?: string;
  selectOptions?: {
    label: any;
    value: any;
  }[];
  dropdownOptions?: {
    text: string;
    value: string;
    selected?: boolean;
  }[];
  getValueProps?: null | ((e: any) => any);
  tooltip?: string;
  rules?: any;
  fieldObject?: object;
};
export const InputComponent = ({
  name,
  index,
  label,
  initialValue,
  description,
  dropdownOptions,
  selectOptions = [],
  getValueProps = null,
  tooltip,
  rules,
  fieldObject = {},
  relationType,
}: InputProps) => {
  const t = useTranslate();
  if (
    index === "multi-select" &&
    (initialValue === null || initialValue === undefined) &&
    dropdownOptions &&
    dropdownOptions.some((o) => o.selected)
  ) {
    initialValue = dropdownOptions.find((o) => o.selected)?.value;
  }

  const [value, setValue] = useState<any>(initialValue);

  const { languageId } = useContext(AuthContext) as IAuthContext;
  const { accessToken } = loadAuthData();
  const quillRef = useRef<any>();
  const props = () => {
    const p: UploadProps = {
      name: "file",
      multiple: false,
      accept: "image/png, image/jpeg",
      showUploadList: {
        showDownloadIcon: false,
      },
      onRemove: async () => {
        setValue(() => null);
      },
      defaultFileList: value
        ? [
            {
              uid: "-1",
              name: "uploaded image.png",
              status: "done",
              url: initialValue,
            },
          ]
        : [],
      maxCount: 1,
      customRequest: async (info: any) => {
        const { onSuccess, onError, file } = info;
        let form = new FormData();
        form.append("file", file);
        const response = await api.file.uploadFile(form);
        if (response.kind === "ok") {
          setValue(() => response.data.url);
          message.success(t("success.upload"));
          onSuccess!(t("success.upload"));
        } else {
          const err = new Error(t("error.upload"));
          message.error(t("error.upload"));
          onError!(err);
        }
      },
      beforeUpload: () => {
        // if you change your minds about rules
        return true;
      },
      headers: {
        "Accept-Language": languageId?.toString() ?? "1",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return p;
  };
  const imageHandler = () => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files![0];
      if (!file) return;
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append("file", file);
        const res = await api.file.uploadFile(formData); // upload data into server or aws or cloudinary
        if (res.kind === "ok") {
          const url = res?.data?.url;
          editor.insertEmbed(editor.getSelection(), "image", url);
        } else {
          message.error(res.kind);
        }
      } else {
        message.error(t("error.onlyImage"));
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  const mappedComponent = () => {
    switch (index) {
      case "text":
        return (
          <Input
            title={description ?? "text field input"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        );
      case "json":
      case "multi-line-text":
        return (
          <Input.TextArea
            rows={4}
            title={description ?? "text field input"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        );
      case "relation":
        return (
          <Select
            mode={relationType === "many-to-many" ? "multiple" : undefined}
            placeholder={t("component:mappedComponent.pleaseSelect")}
            options={
              relationType !== "many-to-many"
                ? [
                    {
                      label: t("component:mappedComponent.pleaseSelect"),
                      value: "",
                    },
                    ...selectOptions,
                  ]
                : selectOptions
            }
            value={
              relationType === "many-to-many"
                ? Array.isArray(value)
                  ? value
                  : []
                : value
            }
            onChange={setValue}
          />
        );
      case "date":
        return <DatePicker value={value} onChange={(e) => setValue(e)} />;
      case "date-time":
        return (
          <DatePicker showTime value={value} onChange={(e) => setValue(e)} />
        );
      case "text-editor":
      case "rich-text-editor":
        return (
          <ReactQuill
            theme="snow"
            ref={quillRef}
            defaultValue={value}
            modules={modules}
          />
        );
      case "number":
        return (
          <InputNumber
            min={-1000000}
            max={1000000}
            value={value}
            onChange={(e) => setValue(e)}
          />
        );
      case "bool":
        return <Switch checked={value} onChange={() => setValue((_) => !_)} />;
      case "image":
      case "file":
        return (
          <Upload.Dragger {...props()}>
            <p className="ant-upload-drag-icon">
              <Icons.InboxOutlined style={defaultIconColor} />
            </p>
            <p className="ant-upload-hint">Görsel Ekle / Sürükle Bırak</p>
            {value && (
              <>
                <img
                  alt="uploaded"
                  src={value}
                  style={{
                    width: "auto",
                    height: "100%",
                    maxWidth: "80%",
                    maxHeight: "400px",
                    objectFit: "cover",
                  }}
                />
              </>
            )}
          </Upload.Dragger>
        );
      case "multi-select":
        if (!dropdownOptions) dropdownOptions = [];

        const selectOptionsOfDropdown = dropdownOptions.map((o) => ({
          label: o.text,
          value: o.value,
        }));

        return (
          <Select
            options={selectOptionsOfDropdown}
            onChange={(e) => setValue(e)}
            value={value}
          />
        );
      default:
        return <h2>{t("component:mappedComponent.default")}</h2>;
    }
  };
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={
        relationType === "many-to-many"
          ? Array.isArray(initialValue)
            ? initialValue
            : []
          : initialValue
      }
      getValueProps={getValueProps || undefined}
      getValueFromEvent={
        index === "file" || index === "image"
          ? (e) => {
              return e.fileList.length ? value : null;
            }
          : undefined
      }
      tooltip={tooltip}
      rules={rules || undefined}
      {...fieldObject}
      key={name}
    >
      {mappedComponent()}
    </Form.Item>
  );
};

import { Api } from "../api";

export class AuthApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async login(email: string, password: string) {
    try {
      const response = await this.api.instance.post("/Authentication/login", {
        email,
        password,
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

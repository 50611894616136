import { Form, FormInstance, Input } from "@pankod/refine-antd";
import { useLogin, useTranslate } from "@pankod/refine-core";
import React, { createRef, useState } from "react";
import { useCookies } from "react-cookie";
import "./login.scss";

export const CustomLoginPage: React.FC = () => {
  const t = useTranslate();

  const [cookies, setCookie] = useCookies();
  const [stayLogged, setStayLogged] = useState<boolean>(
    () => cookies.stayLogged ?? false
  );
  const formRef = createRef<FormInstance>();
  const { mutate: login } = useLogin<{
    email: string;
    password: string;
  }>();
  return (
    <div className="CustomLoginPage">
      <div>
        <h1>{t("login:title")}</h1>
        <div className="Container">
          <Form layout="vertical" ref={formRef}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.email.required"),
                },
                {
                  type: "email",
                  message: t("error.email.invalid"),
                },
              ]}
              name="email"
              label={t("label.email")}
            >
              <Input
                style={{
                  width: "300px",
                  height: "50px",
                }}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t("error.password.required"),
                },
              ]}
              name="password"
              label={t("label.password")}
            >
              <Input.Password
                style={{
                  width: "300px",
                  height: "50px",
                }}
              />
            </Form.Item>
          </Form>
          <div className="horz">
            <input
              type="checkbox"
              name="stay logged in"
              defaultChecked={stayLogged}
              onChange={() => {
                setStayLogged((old) => {
                  setCookie("stayLogged", !old);

                  return !old;
                });
              }}
            />
            <label htmlFor="stay logged in">
              {t("label.rememberMe")}
            </label>
          </div>
          <button
            onClick={async (e) => {
              if (await formRef.current?.validateFields()) {
                login({
                  password: formRef.current?.getFieldValue("password"),
                  email: formRef.current?.getFieldValue("email"),
                });
              }
            }}
          >
            {t("buttons.signIn")}
          </button>
        </div>
      </div>
    </div>
  );
};

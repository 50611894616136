import { Api } from "../api";

export class PagesApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getPages() {
    try {
      const response = await this.api.instance.get("/Pages");
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getAllPagesForDropDown(lanID: string) {
    try {
      const response = await this.api.instance.get("/pages/select-list", {
        headers: {
          "Accept-Language": lanID,
        },
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getInitialValuesPage(id: any) {
    try {
      const response = await this.api.instance.get(`/Pages/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async getInitialValuesContainer(pageID: any, id: any) {
    try {
      const response = await this.api.instance.get(
        `/Pages/${pageID}/containers/${id}`
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async createPage(payLoad: any, languageId: number) {
    try {
      const response = await this.api.instance.post(
        `/Pages?languageId=${languageId}`,
        payLoad,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 201)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async updatePage(payLoad: any, id: any) {
    try {
      const response = await this.api.instance.put(`/Pages/${id}`, payLoad, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async deletePage(id: any) {
    try {
      const response = await this.api.instance.delete(`/Pages/${id}`, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async clonePage(id: any) {
    try {
      const response = await this.api.instance.post(`/Pages/${id}/clone`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!(response.status === 200)) {
        return { kind: "error", message: response.data };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getPageContainers(id: any) {
    try {
      const response = await this.api.instance.get(`/Pages/${id}/containers`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getComponentFields(id: any) {
    try {
      const response = await this.api.instance.get(`/components/${id}/fields`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getComponents() {
    try {
      const response = await this.api.instance.get(`/components`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async createPageContainer(payLoad: any, pageID: number) {
    try {
      const response = await this.api.instance.post(
        `/Pages/${pageID}/containers`,
        payLoad,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 201)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async deletePageContainer(pageID: any, id: any) {
    try {
      const response = await this.api.instance.delete(
        `/Pages/${pageID}/containers/${id}`
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async updatePageContainer(pageID: any, payLoad: any, id: any) {
    try {
      const response = await this.api.instance.patch(
        `/Pages/${pageID}/containers/${id}`,
        payLoad,
        {
          headers: {
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getPreviewURL(pageID: any) {
    try {
      const response = await this.api.instance.get(`/Pages/${pageID}/full-url`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

import api from "api/api";
import { ITable } from "interfaces";
import React, { createContext, useEffect, useState } from "react";

export interface IAuthContext {
  table: ITable[];
  updateTables: (tables: ITable[]) => void;
  languageId: number | undefined;
  updateLanguageId: (id: number) => void;
  tables: ITable[] | undefined;
  getTables: () => Promise<void>;
  profilePicture: string;
  handleProfilePictureChange: () => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const TableProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [languageId, setLanguageId] = useState<number | undefined>();
  const [tables, setTables] = useState<ITable[] | undefined>();
  const [profilePicture, setProfilePicture] = useState("");
  const getTables = async function () {
    const response = await api.tables.getTables();
    if (response.kind !== "ok") return;
    const { data } = response;
    setTables(data);
    const profileResponse = await api.app.getMyApp();
    setProfilePicture(profileResponse.data?.logo ?? "");
  };

  useEffect(() => {
    const getTables = async function () {
      const response = await api.tables.getTables();
      if (response.kind !== "ok") return;
      const { data } = response;
      setTables(data);
      const profileResponse = await api.app.getMyApp();
      setProfilePicture(profileResponse.data?.logo ?? "");
    };
    const id = localStorage.getItem("languageId");
    if (id) {
      setLanguageId(parseInt(id) ?? 1);
    } else {
      setLanguageId(1);
    }
    getTables();
  }, []);

  const updateLanguageId = (id: number) => {
    api.setLanguageHeader(id);
    setLanguageId(id);
    localStorage.setItem("languageId", JSON.stringify(id));
  };

  async function handleProfilePictureChange() {
    const profileResponse = await api.app.getMyApp();
    setProfilePicture(profileResponse.data?.logo ?? "");
  }

  const [table, setTable] = useState<ITable[]>([
    {
      id: "1",
      name: "asd",
    },
  ]);

  const updateTables = (tables: ITable[]) => {
    setTable(tables);
  };

  return (
    <AuthContext.Provider
      value={{
        table,
        updateTables,
        updateLanguageId,
        languageId,
        tables,
        getTables,
        profilePicture,
        handleProfilePictureChange,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

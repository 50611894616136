import {
  Button,
  CloneButton,
  EditButton,
  Icons,
  Input,
  List,
  Modal,
  Space,
  Spin,
  Table,
  TableColumnProps,
  Tooltip,
} from "@pankod/refine-antd";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { IMetaResponse } from "interfaces";
import { useEffect, useRef, useState } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import "@uiw/react-textarea-code-editor/dist.css";
import api from "api/api";
import {
  defaultButtonFilled18,
  defaultHeader,
  defaultOkayButton,
} from "components";
import Highlighter from "react-highlight-words";
const { DeleteFilled } = Icons;

export const CodeList = () => {
  const t = useTranslate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const idToDelete = useRef<string | null>(null);
  const [manualLoading, setManualLoading] = useState(false);
  const [tableData, setTableData] = useState<IMetaResponse[]>();
  const columnTitle = [
    { name: "id", label: t("table.id") },
    { name: "name", label: t("table.name") },
    { name: "headOrBody", label: t("table.headOrBody") },
    { name: "pageNames", label: t("table.pageNames") },
    { name: "_", label: t("table.code") },
  ];
  const [codeToShow, setCodeToShow] = useState<string | null>(null);
  const [showCode, setShowCode] = useState(false);
  const { data, isFetching, refetch } = useCustom<IMetaResponse[]>({
    url: `/code-snippets`,
    method: "get",
  });
  // search
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<any>(null);
  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
  }
  type DataIndex = keyof DataType;

  useEffect(() => {
    if (data) setTableData(data.data);
  }, [data]);

  async function deleteRecord() {
    setManualLoading((old) => !old);
    await api.codeSnippets.deleteCodeSnippet(idToDelete.current as string);
    refetch();
    setManualLoading((old) => !old);
  }

  async function getCodeData(id: string) {
    const response = await api.codeSnippets.getCodeSnippet(id);
    if (response.kind !== "ok") {
      setCodeToShow("error fetching data");
      return;
    }
    const { data } = response;
    setCodeToShow(data["code"]);
  }

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`${t("search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<Icons.SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.search")}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            {t("buttons.clear")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <Icons.SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const sortingData = (item: {
    name: string;
    label: string;
  }): TableColumnProps<IMetaResponse> => {
    let object: TableColumnProps<IMetaResponse> = {};
    if (item.name === "name") {
      object = getColumnSearchProps(item.name);
    }
    return object;
  };

  const columnTitleComponent = columnTitle.map((title) => (
    <Table.Column
      title={title.label}
      key={title.label}
      dataIndex={title.name}
      {...sortingData(title)}
      render={(render, record: IMetaResponse) => {
        if (title.name === "_") {
          return (
            <Button
              onClick={() => {
                setShowCode(true);
                if (tableData) getCodeData(record.id ?? "");
              }}
              style={defaultOkayButton}
              type="ghost"
            >
              {t("buttons.show")}
            </Button>
          );
        }
        if (!render) return "null";
        return String(render);
      }}
    />
  ));
  return (
    <List
      pageHeaderProps={{ title: defaultHeader(t("code:list.title")) }}
      createButtonProps={{
        style: defaultButtonFilled18,
        children: t("buttons.create"),
      }}
    >
      <Table
        rowKey="id"
        loading={isFetching || manualLoading}
        dataSource={tableData}
        showSorterTooltip={false}
        className="ant-table-custom"
      >
        {columnTitleComponent}
        <Table.Column<IMetaResponse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Tooltip placement="top" title={t("buttons.edit")}>
                  <EditButton
                    size="large"
                    recordItemId={record.id!}
                    hideText
                    title={t("buttons.edit")}
                    about={t("buttons.edit")}
                    style={{
                      color: "#0e6d33",
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title={t("buttons.delete")}>
                  <Button
                    icon={
                      <DeleteFilled
                        twoToneColor="#eb2f96"
                        color="red"
                        style={{
                          color: "#ff4343",
                        }}
                      />
                    }
                    size="large"
                    onClick={() => {
                      setShowDeleteModal(true);
                      idToDelete.current = record.id;
                    }}
                  />
                </Tooltip>
                <Tooltip placement="left" title={t("buttons.clone")}>
                  <CloneButton
                    size="large"
                    recordItemId={record.id!}
                    hideText
                    style={{
                      color: "blue",
                    }}
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        title={t("code:list.deleteModal.title")}
        visible={showDeleteModal}
        onOk={async () => {
          if (manualLoading) return;
          setShowDeleteModal(false);
          await deleteRecord();
        }}
        onCancel={() => setShowDeleteModal(false)}
        closable
        okText={t("buttons.delete")}
        cancelText={t("buttons.cancel")}
      >
        {t("code:list.deleteModal.message")}
      </Modal>
      <Modal
        title={t("code:list.codeModal.title")}
        okText={t("buttons.ok")}
        cancelText={t("buttons.cancel")}
        visible={showCode}
        onOk={async () => {
          setShowCode(false);
          setCodeToShow(null);
        }}
        onCancel={() => {
          setShowCode(false);
          setCodeToShow(null);
        }}
        closable
      >
        {codeToShow ? (
          <CodeEditor
            value={codeToShow}
            language="js"
            placeholder=""
            disabled
            onChange={(evn) => {}}
            padding={15}
            className="w-tc-editor-var"
            style={{
              fontSize: 16,
              backgroundColor: "#111",
              border: "1px solid #999",
              borderRadius: "5px",
              minHeight: "125px",
              caretColor: "white",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            }}
          />
        ) : (
          <Spin />
        )}
      </Modal>
    </List>
  );
};

import { Button, Modal, Typography } from "@pankod/refine-antd";
import { DefaultModalOkayButton, defaultOkayButton } from "components";
import { ICreateModule } from "interfaces";
import { useState } from "react";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import { TEXT_CUTOFF_POINT } from "styleObjects";
import { useTranslate } from "@pankod/refine-core";
type Props = { item: ICreateModule; text: any; record: any };
export const TableColumn = ({ item, text, record }: Props) => {
  const t = useTranslate();
  const [jsonData, setJsonData] = useState();
  const [showJsonData, setshowJsonData] = useState(false);
  const [showRichText, setShowRichText] = useState(false);
  const [richData, setRichData] = useState<string | undefined>("");
  const render = () => {
    if (item.type === "rich-text-editor") {
      return (
        <Button
          onClick={() => {
            setRichData(text);
            setShowRichText(true);
          }}
          style={defaultOkayButton}
        >
          {t("buttons.show")}
        </Button>
      );
    }
    if (item.type === "bool") return String(text);
    if (!text) {
      return `<${t("module:empty")}>`;
    }
    if (item.type === "date") {
      return dayjs(text).format("YYYY-MM-DD");
    }
    if (item.type === "date-time") {
      return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
    }
    if (item.type === "json") {
      return (
        <Button
          onClick={() => {
            setshowJsonData(true);
            setJsonData(text);
          }}
          style={defaultOkayButton}
        >
          {t("buttons.show")}
        </Button>
      );
    }
    if (
      item.type === "relation" &&
      item.relationType !== "many-to-many" &&
      item.relationFieldName
    ) {
      return record[item.relationFieldName] ? (
        <Typography.Link
          underline
          onClick={() => {
            setShowRichText(true);
            let html = "";
            // do some magic
            function magicFunction(inputObj: any) {
              for (var key in inputObj) {
                if (typeof inputObj[key] === "object") {
                  html += `<p>${key} : <ul>`;
                  magicFunction(inputObj[key]);
                  html += "</ul> </p>";
                } else {
                  html += `<li>${[key]} : ${inputObj[key]}</li>`;
                }
              }
            }
            for (let key in record[item.relationFieldName ?? ""]) {
              if (key.startsWith("_")) continue;

              if (
                typeof record[item.relationFieldName ?? ""][key] === "object"
              ) {
                html += `<p><strong>${key}</strong>: <ul>`;
                magicFunction(record[item.relationFieldName ?? ""][key]);
                html += "</ul> </p>";
              } else {
                html += `<p><strong>${key}</strong>: ${
                  record[item.relationFieldName ?? ""][key]
                }<p/>`;
              }
            }
            setRichData(html);
          }}
          style={defaultOkayButton}
        >
          {item.relationRecordShowField
            ? record[item.relationFieldName][item.relationRecordShowField]
            : `<${t("module:empty")}>`}
        </Typography.Link>
      ) : (
        `<${t("module:empty")}>`
      );
    }
    if (
      item.type === "relation" &&
      item.relationType === "many-to-many" &&
      item.relationFieldName
    ) {
      return record[item.relationFieldName] ? (
        <>
          {Array.isArray(record[item.relationFieldName])
            ? record[item.relationFieldName].length
            : 1}{" "}
          Adet
        </>
      ) : (
        `<${t("module:empty")}>`
      );
    }
    if (text && text.length > TEXT_CUTOFF_POINT) {
      return (
        <Typography.Paragraph>
          {String(text).slice(0, 100)}
          ...
          <Button
            type="text"
            style={{
              color: "rgb(42, 19, 46)",
            }}
            onClick={() => {
              setShowRichText(true);
              setRichData(text);
            }}
          >
            {t("module:showMore")}
          </Button>
        </Typography.Paragraph>
      );
    }

    return String(text);
  };
  return (
    <>
      {render()}
      <style>
        {`
        a.ant-typography, .ant-typography a {
          color : black
        }
        `}
      </style>
      <Modal
        title={t("module:jsonDataModal.title")}
        visible={showJsonData}
        onCancel={() => {
          setshowJsonData(false);
        }}
        footer={[
          <DefaultModalOkayButton onClick={() => setshowJsonData(false)} />,
        ]}
      >
        <div>{JSON.stringify(jsonData)}</div>
      </Modal>
      <Modal
        title={t("module:richTextModal.title")}
        visible={showRichText}
        onCancel={() => {
          setShowRichText(false);
        }}
        footer={[
          <DefaultModalOkayButton onClick={() => setShowRichText(false)} />,
        ]}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(richData ?? ""),
          }}
        ></div>
      </Modal>
    </>
  );
};

import { IPageResponse } from "interfaces";
import React, { createContext, useState } from "react";

export interface IContainerContext {
  page: number;
  handlePageChange: (e: number) => void;
  updateHeight: () => void;
  manualUpdate: number;
  changeRecordToShow: (e: IPageResponse) => void;
  recordToShow: IPageResponse | null;
  recordToUpdate: number | null;
  handleRecordToChange: (e: number) => void;
}

export const ContainerContext = createContext<IContainerContext | null>(null);

export const ContainerProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [page, setPage] = useState<number>(0);
  const [recordToShow, setRecordToShow] = useState<IPageResponse | null>(null);
  const [manualUpdate, setManualUpdate] = useState(0);
  const [recordToUpdate, setRecordToUpdate] = useState<number | null>(null);
  function handlePageChange(e: number) {
    if (e === 1) setRecordToUpdate(null);
    setPage(e);
  }
  function updateHeight() {
    setManualUpdate((old) => old + 1);
  }
  function changeRecordToShow(e: IPageResponse) {
    setRecordToShow(e);
  }
  function handleRecordToChange(e: number) {
    setRecordToUpdate(e);
  }
  const value = {
    page,
    handlePageChange,
    updateHeight,
    manualUpdate,
    recordToShow,
    changeRecordToShow,
    recordToUpdate,
    handleRecordToChange,
  };
  return (
    <ContainerContext.Provider value={value}>
      {children}
    </ContainerContext.Provider>
  );
};

import {
  Create,
  Form,
  Modal,
  Button,
  Icons,
  FormInstance,
} from "@pankod/refine-antd";
import { ICreateModule } from "interfaces";
import dayjs, { Dayjs } from "dayjs";
import { createRef, useContext, useEffect, useState } from "react";
import {
  useCustom,
  useNavigation,
  useResource,
  useTranslate,
} from "@pankod/refine-core";
import "./style.css";
import { IAuthContext, AuthContext } from "../../AuthContext";
import api from "api/api";
import {
  defaultButtonFilled18,
  defaultHeader,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
  InputComponent,
} from "components";
const { SaveTwoTone } = Icons;

export const CreateModule = () => {
  const t = useTranslate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const nav = useNavigation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const formRef = createRef<FormInstance>();
  const { languageId } = useContext(AuthContext) as IAuthContext;
  const { resourceName, id, resource } = useResource();
  const { data, isFetching, isLoading } = useCustom<ICreateModule[]>({
    url: `/tables/${resourceName}/fields`,
    method: "get",
    config: {
      filters: [
        {
          field: "languageId",
          value: languageId,
          operator: "eq",
        },
      ],
    },
  });
  const savePost = async () => {
    let payLoad: any = {
      record: {},
      languageId: languageId,
    };
    data?.data?.map?.((e: ICreateModule) => {
      switch (e.type) {
        case "id":
          break;
        case "bool":
          const booleanValue = document.getElementById(
            e.systemName
          ) as HTMLInputElement;
          payLoad.record[e.systemName] =
            booleanValue.value === "true" ? true : false;
          break;
        case "date":
          const dateValue = formRef.current?.getFieldValue(
            e.systemName
          ) as Dayjs;
          if (dateValue)
            payLoad.record[e.systemName] = dateValue.format("YYYY-MM-DD");
          break;
        case "date-time":
          const dateTimeValue = formRef.current?.getFieldValue(
            e.systemName
          ) as Dayjs;
          if (dateTimeValue)
            payLoad.record[e.systemName] = dateTimeValue.format(
              "YYYY-MM-DDTHH:mm:ss"
            );
          break;
        case "relation":
          if (formRef.current?.getFieldValue(e.systemName)) {
            payLoad.record[e.systemName] = formRef.current.getFieldValue(
              e.systemName
            );
          }
          break;
        default:
          if (formRef.current?.getFieldValue(e.systemName))
            payLoad.record[e.systemName] = formRef.current?.getFieldValue(
              e.systemName
            );
          break;
      }
      return e;
    });
    const response = await api.tables.postRecords(resourceName, payLoad);
    if (response.kind === "ok") {
      setShowSuccess(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      let retries = 5;
      const interval = setInterval(async () => {
        if (!retries) clearInterval(interval);
        const response = await api.tables.getRecord(resourceName, id as string);
        if (response.kind !== "ok") {
          retries--;
          return;
        }
        clearInterval(interval);
        setInitialValues(response.data);
      }, 500);
    };
    if (id && data) getData();
  }, [data, id, resourceName]);

  return (
    <>
      <Create
        pageHeaderProps={{
          onBack: () => {
            setIsModalVisible(true);
          },
          title: defaultHeader(resource.label + ""),
        }}
        isLoading={isFetching || isLoading}
        actionButtons={[
          <Button
            icon={<SaveTwoTone twoToneColor="white" />}
            style={defaultButtonFilled18}
            onClick={async () => {
              await savePost();
            }}
          >
            {t("buttons.save")}
          </Button>,
        ]}
      >
        <Form layout="vertical" ref={formRef}>
          {(!id || initialValues) &&
            !isLoading &&
            data &&
            !isFetching &&
            data?.data.map?.((e: ICreateModule) => {
              switch (e.type) {
                case "file":
                case "multi-line-text":
                case "bool":
                case "number":
                case "rich-text-editor":
                case "text":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues ? initialValues[e.systemName] : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                    />
                  );
                // if it is a relation
                case "relation":
                  return (
                    <InputComponent
                      index={e.type}
                      relationType={e.relationType}
                      initialValue={
                        initialValues ? initialValues[e.systemName] ?? "" : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      selectOptions={e.relationTableRecords?.map((e) => {
                        return {
                          label: e.name,
                          value: e.id,
                        };
                      })}
                    />
                  );
                case "date":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? initialValues[e.systemName]
                            ? dayjs(initialValues[e.systemName])
                            : null
                          : null
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        };
                      }}
                    />
                  );
                case "date-time":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? initialValues[e.systemName]
                            ? dayjs(initialValues[e.systemName])
                            : null
                          : null
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                      getValueProps={(value) => {
                        return {
                          value: value ? dayjs(value) : "",
                        };
                      }}
                    />
                  );
                case "json":
                  return (
                    <InputComponent
                      index={e.type}
                      initialValue={
                        initialValues
                          ? JSON.stringify(initialValues[e.systemName])
                          : ""
                      }
                      label={e.name}
                      name={e.systemName}
                      key={e.systemName}
                      description={e.description}
                    />
                  );
                default:
                  return null;
              }
            })}
        </Form>
      </Create>
      <Modal
        title={t("module:create.modal.title")}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{
          title: t("buttons.ok"),
        }}
        cancelButtonProps={{
          title: t("buttons.cancel"),
        }}
        footer={[
          <DefaultModalCancelButton onClick={() => setIsModalVisible(false)} />,
          <DefaultModalOkayButton onClick={() => nav.goBack()} />,
        ]}
      >
        <p>{t("module:create.modal.message")}</p>
      </Modal>
      <Modal
        title={t("module:create.successModal.title")}
        closable
        visible={showSuccess}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
      >
        <p>{t("module.create.successModal.message")}</p>
      </Modal>
    </>
  );
};

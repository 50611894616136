import { Api } from "../api";

export class LayoutAPI {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getLayOuts(langID: any, type: "header" | "footer") {
    try {
      const response = await this.api.instance.get(
        `/layout-containers?languageId=${langID}&footerOrHeader=header`
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getSingleLayout(id: any) {
    try {
      const response = await this.api.instance.get(`/layout-containers/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
  async patchLayout(id: any, payLoad) {
    try {
      const response = await this.api.instance.patch(
        `/layout-containers/${id}`,
        payLoad
      );
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

import { PageView } from "foundation/pageview/PageView";
import { IPageResponse } from "interfaces";
import { useContext, useEffect } from "react";
import { ContainerContext, IContainerContext } from "./ContainerContext";
import { ContainerMutate } from "./ContainerMutate";
import { ContainerList } from "./ContainerList";
import { ContainerEdit } from "./ContainerEdit";
export const ContainerModal = ({ record }: { record: IPageResponse }) => {
  const { page, changeRecordToShow, handlePageChange } = useContext(
    ContainerContext
  ) as IContainerContext;
  changeRecordToShow(record);

  useEffect(() => {
    handlePageChange(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <PageView page={page} width="100%" className="">
      <ContainerEdit />
      <ContainerList />
      <ContainerMutate />
    </PageView>
  );
};

import {
  Button,
  Create,
  Form,
  FormInstance,
  Icons,
  Input,
  message,
  Modal,
  Select,
} from "@pankod/refine-antd";
import { useNavigation, useResource, useTranslate } from "@pankod/refine-core";
import { ICodePayload, IPageId } from "interfaces";
import { createRef, useContext, useEffect, useState } from "react";
import { buttonStyle, LOOPABLE_ITEMS_CODE } from "styleObjects";
import CodeEditor from "@uiw/react-textarea-code-editor";
import "@uiw/react-textarea-code-editor/dist.css";
import api from "api/api";
import {
  defaultHeader,
  DefaultModalCancelButton,
  DefaultModalOkayButton,
} from "components";
import { AuthContext, IAuthContext } from "AuthContext";
const { SaveTwoTone, CloseCircleFilled } = Icons;
export const CodeEdit = () => {
  const t = useTranslate();
  const { TextArea } = Input;
  const formRef = createRef<FormInstance>();
  // to prevent react keeping state of _ forms we use keyscount this way new forms will not have _ one values
  // we are setting key to the item itemKey attr
  const [loading, setLoading] = useState(false);
  const [selectedPagesID, setSelectedPagesID] = useState<IPageId[]>([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { id } = useResource();
  const nav = useNavigation();
  const [initialValues, setInitialValues] = useState<ICodePayload | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [pagesID, setPagesID] = useState<IPageId[]>();
  const [code, setCode] = useState<string>("");
  const { languageId } = useContext(AuthContext) as IAuthContext;

  useEffect(() => {
    async function getInitialValues() {
      setLoading((_) => !_);
      const response = await api.pages.getAllPagesForDropDown(
        (languageId ?? 0)?.toString()
      );
      if (response.kind !== "ok") return;
      const { data } = response;
      setPagesID([...data]);
      if (id) {
        const response2 = await api.codeSnippets.getCodeSnippet(id as string);
        if (response2.kind !== "ok") return;
        if (response2.data.pages) {
          const tempPages: IPageId[] = [];
          response.data.map((item: IPageId) => {
            if (response2.data.pages.includes(item.id)) tempPages.push(item);
            return item;
          });
          setSelectedPagesID(tempPages);
        }
        setInitialValues(response2.data);
      }
      setLoading((_) => !_);
    }

    getInitialValues();
  }, [id, languageId]);

  async function createNewCode() {
    let payLoad: ICodePayload = {};
    LOOPABLE_ITEMS_CODE(t).map((item) => {
      if (formRef.current?.getFieldValue(item.name))
        payLoad[item.name] = formRef.current?.getFieldValue(item.name);
      return item;
    });
    setLoading((_) => !_);
    const newPay: any = payLoad;

    newPay.pages = newPay.pages ? [newPay.pages] : [];
    if (selectedPagesID.length !== 0) {
      const filteredIDs: number[] = [];
      selectedPagesID.map((item) => filteredIDs.push(item.id));
      newPay.pages = filteredIDs;
    }
    const response = await api.codeSnippets.putCodeSnippet(
      id as string,
      newPay
    );
    if (response.kind === "ok") setShowModal(true);
    if (response.kind !== "ok") message.error(response.kind);

    setLoading((_) => !_);
  }
  return (
    <Create
      actionButtons={[
        <Button
          icon={<SaveTwoTone color="white" twoToneColor="white" />}
          style={buttonStyle}
          onClick={createNewCode}
        >
          {t("buttons.create")}
        </Button>,
      ]}
      pageHeaderProps={{
        onBack: () => setShowWarningModal(true),
        title: defaultHeader(t("code:edit.title")),
      }}
      isLoading={loading}
    >
      {(!id || !loading) && (
        <Form layout="vertical" ref={formRef}>
          {LOOPABLE_ITEMS_CODE(t).map((name) =>
            name.name === "pages" ? (
              <Form.Item
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={
                  initialValues
                    ? pagesID?.find((element) => {
                        if (initialValues.pages?.includes(element.id)) {
                          return element;
                        }
                        return false;
                      })?.id
                    : ""
                }
              >
                <Select
                  options={pagesID?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })}
                  onSelect={(e: number) => {
                    setSelectedPagesID((old) => {
                      if (!pagesID) return old;
                      const includes = old.some((item) => item.id === e);
                      const filtered = includes
                        ? [...old]
                        : [...old, ...pagesID?.filter((item) => item.id === e)];
                      return filtered as IPageId[];
                    });
                  }}
                />
                <div className="selectedPageID-wrapper">
                  {selectedPagesID.map((item) => {
                    return (
                      <div className="selectedPageID">
                        <h1> {item.name}</h1>{" "}
                        {
                          <CloseCircleFilled
                            className="deleteIcon"
                            onClick={() => {
                              setSelectedPagesID((old) =>
                                old.filter((e) => item.id !== e.id)
                              );
                            }}
                          />
                        }
                      </div>
                    );
                  })}
                </div>
              </Form.Item>
            ) : name.name === "headOrBody" ? (
              <Form.Item
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : "Head"}
              >
                <Select
                  options={[
                    {
                      label: "Head",
                      value: 1,
                    },
                    {
                      label: "Body",
                      value: 2,
                    },
                  ]}
                />
              </Form.Item>
            ) : name.name === "description" ? (
              <Form.Item
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : ""}
              >
                <TextArea rows={5} />
              </Form.Item>
            ) : name.name === "code" ? (
              <Form.Item
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : ""}
              >
                <CodeEditor
                  value={code}
                  language="js"
                  placeholder=""
                  onChange={(evn) => setCode(evn.target.value)}
                  padding={15}
                  className="w-tc-editor-var"
                  style={{
                    fontSize: 16,
                    backgroundColor: "#111",
                    border: "1px solid #999",
                    borderRadius: "5px",
                    minHeight: "125px",
                    caretColor: "white",

                    fontFamily:
                      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={name.label}
                tooltip={name.tooltip}
                name={name.name}
                key={name.label}
                initialValue={initialValues ? initialValues[name.name] : ""}
              >
                <Input />
              </Form.Item>
            )
          )}
        </Form>
      )}
      <Modal
        closable
        title={t("code:edit.successModal.title")}
        visible={showModal}
        onOk={() => nav.goBack()}
        onCancel={() => nav.goBack()}
        footer={[<DefaultModalOkayButton onClick={() => nav.goBack()} />]}
      >
        {t("code:edit.successModal.message")}
      </Modal>
      <Modal
        closable
        title={t("code:edit.warningModal.title")}
        visible={showWarningModal}
        onOk={() => nav.goBack()}
        onCancel={() => setShowWarningModal(false)}
        footer={[
          <DefaultModalCancelButton
            onClick={() => setShowWarningModal(false)}
          />,
          <DefaultModalOkayButton onClick={() => nav.goBack()} />,
        ]}
      >
        <p>{t("code:edit.warningModal.message")}</p>
      </Modal>
    </Create>
  );
};

// Author Yasir Kadhim
// 21/5/2022
// code for page view
// proptypes will be laid down below

import {
  ContainerContext,
  IContainerContext,
} from "pages/pages/container/ContainerContext";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import "./PageView.scss";

type Prop = {
  children: any;
  className?: string;
  width?: string;
  height?: string;
  page: number;
};
export function PageView({ children, className, width, page }: Prop) {
  const [height, setHeight] = useState(0);
  const pageRef = useRef<number>(page);
  const { manualUpdate } = useContext(ContainerContext) as IContainerContext;
  useEffect(() => {
    try {
      const h = document.getElementById(`Page${page}`)?.clientHeight;
      if (h) setHeight(h);
    } catch (err) {
      //
    }
    pageRef.current = page;
  }, [page, manualUpdate]);

  useEffect(() => {
    const intr = setInterval(() => {
      try {
        const h = document.getElementById(
          `Page${pageRef.current}`
        )?.clientHeight;
        if (h) setHeight(h);
      } catch (err) {
        //
      }
    }, 300);
    return () => {
      clearInterval(intr);
    };
  }, []);

  return (
    <div
      className={"PageView" + className}
      style={{
        width: width,
        height: height,
      }}
    >
      {children!.map((item: ReactNode, index: number) => (
        <div
          className={"Page"}
          key={index}
          id={"Page" + index}
          style={{
            transform: "translateX(" + (index - page) + "00%)",
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

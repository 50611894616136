import React, { createRef, useContext, useState } from "react";
import {
  message,
  RcFile,
  Upload,
  Show,
  Modal,
  Input,
  Form,
  FormInstance,
  Button,
} from "@pankod/refine-antd";

import { BsImage, BsUpload } from "react-icons/bs";
import "./home.scss";
import { useCustom, useTranslate } from "@pankod/refine-core";
import { IAppDetails } from "interfaces";
import api from "api/api";
import { AuthContext, IAuthContext } from "AuthContext";
import { defaultButtonFilled, defaultHeader } from "components";

export const HomePage: React.FC = () => {
  const t = useTranslate();
  const [imageUrl, setImageUrl] = useState<File>();
  const [faviconUrl, setFaviconUrl] = useState<File>();
  //
  const [showSuccess, setShowSuccess] = useState(false);
  const [logoPreview, setLogoPreview] = useState<string>();
  const [faviconPreview, setFaviconPreview] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const { handleProfilePictureChange } = useContext(
    AuthContext
  ) as IAuthContext;
  const [error, setError] = useState("");
  const formRef = createRef<FormInstance>();

  const uploadButton = (
    <div>
      <BsImage />
      <div style={{ marginTop: 8 }}>laga</div>
    </div>
  );
  const uploadButton2 = (
    <div>
      <BsImage />
      <div style={{ marginTop: 8 }}>Favicon</div>
    </div>
  );

  const hoverEdit = (
    <div className="hoverEdit">
      <BsUpload className="icon" />
    </div>
  );

  // init data
  const { data, isLoading, refetch, isRefetching } = useCustom<IAppDetails>({
    url: `/apps/my-app`,
    method: "get",
  });

  async function handleEditing() {
    if (editing) {
      if (
        formRef.current?.getFieldValue("name") !== data?.data.name ||
        formRef.current?.getFieldValue("email") !== data?.data.email ||
        formRef.current?.getFieldValue("gai") !==
          data?.data.googleAnalyticsViewId ||
        imageUrl ||
        faviconUrl
      ) {
        if (await formRef.current?.validateFields()) {
          handleProfilePictureChange();
          setShowModal(true);
        } else {
          setEditing(false);
        }
      } else {
        setEditing(false);
      }
    } else {
      setEditing(true);
    }
  }

  const beforeUpload = (file: RcFile, id: number) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t("error.fileFormat"));
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("error.fileSize"));
    }
    if (isJpgOrPng && isLt2M) {
      if (id === 1) {
        setLogoPreview(URL.createObjectURL(file));
        setImageUrl(file);
      } else {
        setFaviconPreview(URL.createObjectURL(file));
        setFaviconUrl(file);
      }
      return true;
    } else {
      setImageUrl(undefined);
      return false;
    }
  };

  return (
    <Show
      isLoading={isLoading || isRefetching}
      pageHeaderProps={{
        extra: null,
        title: defaultHeader(t("home:title")),
      }}
    >
      <div className="AppRoute">
        <div className="vert">
          <div className="horzt">
            <div>
              <span className="upc">{t("home:logo")}</span>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(e) => beforeUpload(e, 1)}
                disabled={!editing}
                customRequest={(e) => {
                  // nothing
                }}
              >
                {imageUrl ? (
                  <img
                    src={logoPreview}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : data?.data.logo ? (
                  <img
                    src={data?.data.logo}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
                {hoverEdit}
              </Upload>
            </div>
            <div>
              <span className="upc">{t("home:favicon")}</span>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(e) => beforeUpload(e, 2)}
                disabled={!editing}
                customRequest={(e) => {
                  // nothing
                }}
              >
                {faviconPreview ? (
                  <img
                    src={faviconPreview}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : data?.data.favicon ? (
                  <img
                    src={data?.data.favicon}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton2
                )}
                {hoverEdit}
              </Upload>
            </div>
          </div>
          <Form layout="vertical" ref={formRef}>
            {data ? (
              <>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t("error.email.required"),
                    },
                    {
                      type: "email",
                      message: t("error.email.invalid"),
                    },
                  ]}
                  name="email"
                  label={t("label.email")}
                  initialValue={data?.data.email ?? ""}
                >
                  <Input
                    style={{
                      width: "300px",
                      height: "50px",
                    }}
                    disabled={!editing}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t("error.appName.required"),
                    },
                  ]}
                  name="name"
                  label={t("label.appName")}
                  initialValue={data?.data.name ?? ""}
                >
                  <Input
                    style={{
                      width: "300px",
                      height: "50px",
                    }}
                    disabled={!editing}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t("error.gaId.required"),
                    },
                  ]}
                  name="gai"
                  label={t("label.gaId")}
                  initialValue={data?.data.googleAnalyticsViewId ?? ""}
                >
                  <Input
                    style={{
                      width: "300px",
                      height: "50px",
                    }}
                    disabled={!editing}
                  />
                </Form.Item>
              </>
            ) : null}
          </Form>
          {error && <p className="errorText">{error}</p>}
          <button
            type="submit"
            style={{
              backgroundColor: editing ? "#67be23 " : "#2a132e ",
            }}
            onClick={handleEditing}
          >
            {editing ? t("buttons.save") : t("buttons.edit")}
          </button>
        </div>
      </div>

      <Modal
        title={t("home:warningModal.title")}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            style={defaultButtonFilled}
          >
            {t("buttons.cancel")}
          </Button>,
          <Button
            style={defaultButtonFilled}
            onClick={async () => {
              let form = new FormData();
              form.append("Name", formRef.current?.getFieldValue("name"));
              form.append("Email", formRef.current?.getFieldValue("email"));
              form.append(
                "GoogleAnalyticsViewId",
                formRef.current?.getFieldValue("gai")
              );
              if (imageUrl) form.append("Logo", imageUrl);
              if (faviconUrl) form.append("Favicon", faviconUrl);

              const response = await api.app.patchMyApp(form);
              if (response.kind === "ok") {
                await refetch();
                setFaviconUrl(undefined);
                setImageUrl(undefined);
                setLogoPreview("");
                setFaviconPreview("");
                setError("");
                setEditing(false);
                setShowSuccess(true);
              } else {
                setError("Error");
              }

              setShowModal(false);
            }}
          >
            {t("buttons.ok")}
          </Button>,
        ]}
      >
        <p>{t("home:warningModal.message")}</p>
      </Modal>
      <Modal
        title={t("home:successModal.title")}
        visible={showSuccess}
        onOk={() => {
          setShowSuccess(false);
        }}
        closable
        cancelButtonProps={{
          hidden: true,
        }}
        footer={[
          <Button
            style={defaultButtonFilled}
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            {t("buttons.ok")}
          </Button>,
        ]}
      >
        <p>{t("home:successModal.message")}</p>
      </Modal>
    </Show>
  );
};


export function loadString(key: string): string | null {
    try {
        return localStorage.getItem(key)
    } catch {

        return null
    }
}


export function saveString(key: string, value: string): boolean {
    try {
        localStorage.setItem(key, value)
        return true
    } catch {
        return false
    }
}


export function load(key: string): any | null {
    try {
        const almostThere = localStorage.getItem(key) ?? ""
        return JSON.parse(almostThere)
    } catch {
        return null
    }
}


export function save(key: string, value: any): boolean {
    try {
        localStorage.setItem(key, JSON.stringify(value))
        return true
    } catch {
        return false
    }
}


export function remove(key: string): void {
    try {
        localStorage.removeItem(key)
    } catch { }
}


export function clear(): void {
    try {
        localStorage.clear()
    } catch { }
}
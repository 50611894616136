import { Select } from "@pankod/refine-antd";
import { FieldChangedCallback, IComponentField } from "./interfaces";

const { Option } = Select;

export function ComponentFormMultiSelectField({
  field,
  data,
  fieldChanged,
  location,
}: {
  field: IComponentField;
  data: any;
  fieldChanged: FieldChangedCallback;
  location: string[];
}) {
  if (!Array.isArray(field.options)) field.options = [];

  const options = field.options.map((opt) => ({
    label: opt.text,
    value: opt.value,
  }));

  if (field.multiSelectOptionTemplate) {
    const scriptTags = document
      .createRange()
      .createContextualFragment(field.multiSelectOptionTemplate)
      .querySelectorAll("script");

    scriptTags.forEach((scriptTag) => {
      try {
        eval(scriptTag.innerText);
      } catch {
        // Ignore.
      }
    });
  }

  return field.multiSelectOptionTemplate ? (
    <Select
      value={data ?? null}
      onChange={(e) => fieldChanged([...location, field.name], e)}
    >
      {options.map((option, index) => (
        <Option value={option.value} label={option.label} key={index}>
          <div
            dangerouslySetInnerHTML={{
              __html: field
                .multiSelectOptionTemplate!.replace("{text}", option.label)
                .replace("{value}", option.value),
            }}
          ></div>
        </Option>
      ))}
    </Select>
  ) : (
    <Select
      options={options}
      value={data ?? null}
      onChange={(e) => fieldChanged([...location, field.name], e)}
    />
  );
}

import { Api } from "../api";

export class FormsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async editFormEmails(id: number, to: string, cc: string) {
    try {
      const response = await this.api.instance.patch(`/forms/${id}`, {
        emailTo: to,
        emailCc: cc,
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      return { kind: "ok" };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async getFormEmailResponse(id: number) {
    try {
      const response = await this.api.instance.get(`/forms/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

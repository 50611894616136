import { message } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import api from "api/api";
import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type Type = {
  value?: string;
  setValue?: (e: any) => void;
  defaultValue?: string;
};
export function CustomQuill({
  value = "",
  setValue = (e: string) => {},
  defaultValue = "",
}: Type) {
  const t = useTranslate();
  const quillRef = useRef<any>();

  const imageHandler = (e: any) => {
    const editor = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files![0];
      if (!file) return;
      if (/^image\//.test(file.type)) {
        console.log(file);
        const formData = new FormData();
        formData.append("file", file);
        const res = await api.file.uploadFile(formData); // upload data into server or aws or cloudinary
        if (res.kind === "ok") {
          const url = res?.data?.url;
          editor.insertEmbed(editor.getSelection(), "image", url);
        } else {
          message.error(res.kind);
        }
      } else {
        message.error(t("error.onlyImage"));
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <ReactQuill
      value={value}
      onChange={setValue}
      theme="snow"
      ref={quillRef}
      defaultValue={defaultValue}
      modules={modules}
    />
  );
}

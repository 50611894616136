import { Api } from "../api";

export class MetaTagsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }
  async getMetaTag(id: string) {
    try {
      const response = await this.api.instance.get(`/MetaTags/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async postMetaTag(payload: any) {
    try {
      const response = await this.api.instance.post("/MetaTags", payload, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      });
      if (!(response.status === 201)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async putMetaTag(id: string, payload: any) {
    try {
      const response = await this.api.instance.put(`/MetaTags/${id}`, payload, {
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      });
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async deleteMetaTag(id: string) {
    try {
      const response = await this.api.instance.delete(`/MetaTags/${id}`);
      if (!(response.status === 200)) {
        return { kind: "error" };
      }
      const { data } = response;
      return { kind: "ok", data };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}

import { Button, Icons, Space, Typography } from "@pankod/refine-antd";
import { ComponentFormField } from "./ComponentFormField";
import { FieldChangedCallback, IComponentField } from "./interfaces";
import { useTranslate } from "@pankod/refine-core";

export function ComponentFormArrayField({
  field,
  data,
  fieldChanged,
  location,
}: {
  field: IComponentField;
  data: any[];
  fieldChanged: FieldChangedCallback;
  location: string[];
}) {
  const t = useTranslate();
  if (!Array.isArray(data)) data = [];

  return (
    <div
      style={{
        margin: "10px 0",
        padding: "10px",
        borderRadius: "6px",
        border: "1px solid #ddd",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={2}>
          {field.label ?? field.name}
        </Typography.Title>
        <Button
          type="dashed"
          onClick={() => fieldChanged([...location, field.name], [...data, {}])}
          style={{ marginBottom: ".5em" }}
        >
          <Icons.PlusOutlined /> {t("buttons.create")}
        </Button>
      </div>

      {data.map((item, index) => (
        <Space
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            borderRadius: "6px",
            border: "1px solid #ddd",
            margin: "20px 0",
            width: "100%",
          }}
          align="start"
        >
          <Space
            style={{
              width: "100%",
            }}
            align="start"
            direction="horizontal"
          >
            <Typography.Title level={3} type="secondary">
              {`${field.label ?? field.name} #${index + 1}`}
            </Typography.Title>
            <Icons.MinusCircleOutlined
              onClick={() =>
                fieldChanged(
                  [...location, field.name],
                  [...data.slice(0, index), ...data.slice(index + 1)]
                )
              }
              style={{
                float: "right",
              }}
            />
          </Space>
          {field.subFields?.map((subField) => (
            <ComponentFormField
              field={subField}
              data={item}
              key={subField.name}
              fieldChanged={fieldChanged}
              location={[...location, field.name, index.toString()]}
            />
          ))}
        </Space>
      ))}
    </div>
  );
}

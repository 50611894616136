import { Space, Typography } from "@pankod/refine-antd";
import { ComponentFormField } from "./ComponentFormField";
import { FieldChangedCallback, IComponentField } from "./interfaces";

export function ComponentFormObjectField({
  field,
  data,
  fieldChanged,
  location,
}: {
  field: IComponentField;
  data: any;
  fieldChanged: FieldChangedCallback;
  location: string[];
}) {
  if (typeof data != "object" || Array.isArray(data) || !data) {
    data = {};
  }

  return (
    <div
      style={{
        margin: "10px 0",
      }}
    >
      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          borderRadius: "6px",
          border: "1px solid #ddd",
          margin: "20px 0",
          width: "100%",
        }}
        align="start"
      >
        <Space
          style={{
            width: "100%",
          }}
          align="start"
          direction="horizontal"
        >
          <Typography.Title level={2}>{`${
            field.label ?? field.name
          }`}</Typography.Title>
        </Space>
        {field.subFields?.map((subField) => (
          <ComponentFormField
            field={subField}
            data={data}
            key={subField.name}
            fieldChanged={fieldChanged}
            location={[...location, field.name]}
          />
        ))}
      </Space>
    </div>
  );
}

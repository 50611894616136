import moment from "moment";
import { load, save } from "../storage/storage";
const AUTH_KEY = "acerpro-auth";

interface AuthData {
  accessToken?: string;
  accessTokenExpire?: string;
  refreshToken?: string;
  refreshTokenExpire?: string;
}

export function loadAuthData(): AuthData {
  try {
    const authData = load(AUTH_KEY);

    return authData ?? {};
  } catch {
    return {};
  }
}

export function saveAuthData(authData: AuthData): boolean {
  try {
    save(AUTH_KEY, authData);

    return true;
  } catch {
    return false;
  }
}

export function clearAuthData(): boolean {
  try {
    console.log("====================================");
    console.log("clearing");
    console.log("====================================");
    const cleared = saveAuthData({});

    return cleared;
  } catch {
    return false;
  }
}

export function loadAuthToken(): string | null {
  try {
    const authData = loadAuthData();

    return authData.accessToken ?? null;
  } catch {
    return null;
  }
}

export function saveAuthToken(value: string): boolean {
  try {
    let authData = loadAuthData();
    authData.accessToken = value;

    const saved = saveAuthData(authData);

    return saved;
  } catch {
    return false;
  }
}

export function clearAuthToken(): boolean {
  try {
    let authData = loadAuthData();
    delete authData.accessToken;

    const saved = saveAuthData(authData);

    return saved;
  } catch {
    return false;
  }
}

export function checkIsTokenExpired(): boolean {
  try {
    const authData = loadAuthData();
    const { accessTokenExpire, refreshTokenExpire } = authData;
    if (
      moment(accessTokenExpire).isAfter(moment()) &&
      moment(refreshTokenExpire).isAfter(moment())
    ) {
      return false;
    }
    return true;
  } catch {
    return true;
  }
}
